import { lazy, useState, useEffect } from "react";
import { FlexRow, CustomSLButton, Spacer, UltraMediumText, MediumText, LargeText, Header, SLButton } from "../../components/core/utility";
import { EDIT_VEHICLE_MMID_COL } from "../../constants/AddAVehicleConstants";
import styled from "styled-components";
import { generate } from "shortid";

import { Icon } from "@vds/icons";
import FailedIcon from "../../assets/icons/ErrorIcon.svg";
import { CustomDropdown } from "./AddAVehicle";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { redirectToPrevPage, getFullName, TYPE_DROPDOWN_VALUES } from "./utils";
import { Button } from "@vds/buttons";
import { Modal, ModalBody } from "@vds/modals";
import { css } from "@emotion/react";
import { Title } from "@vds/typography";
const InfoContainer = styled.div`
  width: calc(25% - 1rem);
  margin: 0rem 0.5rem 0rem 0.5rem;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  color: #ffffff;
  background-color: #ffffff;
  display: flex;
  flex-flow: column wrap;
  border-left: solid #ced4da 1px;
`;

const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  border-radius: 0.5rem;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;

export default function EditDetails({ data, description }) {
  let recommendations = ["Xirgo FGUS02-Priority1", "VDD-Priority2", "Xirgo FGUS03-Priority3"];
  let reasons = ["Temporary stock unavailability", "Future compatibility assurance", "Contractual exception", "PEX/T3 recommended"];
  const [bundles, setBundles] = useState([]);
  const [historicalAddress, setHistoricalAddress] = useState([]);
  const [shippingAddress, setShippingAddress] = useState({ value: "", error: false, errorText: "Select Address", success: false });
  const [bundle, setBundle] = useState({ value: "", error: false, errorText: "Select install type", success: false });
  const [hardawareSelectionModal, setHardwareSelectionModal] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState({
    value: "",
    error: false,
    errorText: "Select Recommendation",
    success: false,
  });
  const [selectedReason, setSelectedReason] = useState({ value: "", error: false, errorText: "Select Reason", success: false });
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState(true);
  const [vehicleMMIDTableList, setVehicleMMIDTableList] = useState([]);

  useEffect(() => {
    let mmidTableList = data?.materials.map((records) => {
      let upsellUI = records.upsell == true ? "Yes" : records.upsell == false ? "No" : "--";
      let record = {
        id: generate(),
        mId: records.id,
        materialId: records.materialId,
        itemDescription: records.materialName,
        upsell: records.upsell,
        upsellForUI: upsellUI,
        type: records.vmfType,
        displayType: getFullName(TYPE_DROPDOWN_VALUES, records.vmfType),
        techRefID: records.techRefId,
        contractItem: records.vmfSapName,
        validMMID: records.validMMID,
        sapIdVal: records.vmfSapId,
        sapId: records.vmfSapIdVal,
        orderType: records.orderType,
        bundleId: records.bundleId ? records.bundleId : "",
        vinMaterialFeatures: records.vinMaterialFeatures,
        priority: data.priority,
      };
      return record;
    });
    setVehicleMMIDTableList(mmidTableList);
    let bundleData = description.split(")");
    let address = `${data.addressOne} ${data.addressTwo} ${data.city} ${data.stateCode} ${data.zipCode}`;
    handleFields("shippingAddress", address);
    handleFields("bundle", description);
    handleFields("recommendation", recommendations[0]);
    handleFields("reason", reasons[0]);
    /*eslint-disable*/
    let addressArray = [...historicalAddress].concat(address);
    let bundleArray = [...bundles].concat(`${bundleData[0]})`);
    setBundles(bundleArray);
    setHistoricalAddress(addressArray);
  }, []);
  useEffect(() => {
    if (shippingAddress.success && bundle.success && vehicleMMIDTableList.length) {
      setIsSaveBtnDisabled(false);
    } else {
      setIsSaveBtnDisabled(true);
    }
  }, [shippingAddress.success, bundle.success, vehicleMMIDTableList.length]);
  useEffect(() => {
    if (selectedReason.success && selectedRecommendation.success) {
      setIsConfirmBtnDisabled(false);
    } else {
      setIsConfirmBtnDisabled(true);
    }
  }, [selectedReason.success, selectedRecommendation.success]);
  const handleFields = (field, value, index, id = "") => {
    switch (field) {
      case "shippingAddress": {
        value && value.length
          ? setShippingAddress({ value, error: false, errorText: "Select Bundle", success: true })
          : setShippingAddress({ value, error: true, errorText: "Select Bundle", success: false });
        break;
      }
      case "bundle": {
        value && value.length
          ? setBundle({ value, error: false, errorText: "Select Bundle", success: true })
          : setBundle({ value, error: true, errorText: "Select Bundle", success: false });
        break;
      }
      case "recommendation": {
        value && value.length
          ? setSelectedRecommendation({ value, error: false, errorText: "Select Recommendation", success: true })
          : setSelectedRecommendation({ value, error: true, errorText: "Select Recommendation", success: false });
        break;
      }
      case "reason": {
        value && value.length
          ? setSelectedReason({ value, error: false, errorText: "Select Reason", success: true })
          : setSelectedReason({ value, error: true, errorText: "Select Reason", success: false });
        break;
      }
    }
  };
  const closeHandler = () => {
    setHardwareSelectionModal(false);
    setSelectedReason({ value: "", error: true, errorText: "Select Reason", success: false });
    setSelectedRecommendation({ value: "", error: true, errorText: "Select Recommendation", success: false });
  };
  const confirmEnableScreen = () => {
    setHardwareSelectionModal(false);
  };
  // const showEditItemModal = (row) => {
  //     setAddVehicleMMIDModal(true);
  //     setExistingItemDetails(row);
  // };

  const showVehicleDeleteModal = (rowData) => {
    let tableData = _.cloneDeep(vehicleMMIDTableList).filter((item) => item.id !== rowData.id);
    setVehicleMMIDTableList(tableData);
  };

  const showActionIcon = (row) => (
    <FlexRow>
      {/* <div onClick={() => showEditItemModal(row)}> */}
      <div style={{ marginRight: "0.5rem" }}>
        <Icon role="img" aria-label="edit" alt="edit-icon" name="edit" size="medium" color="#000000" style={{ cursor: "pointer", outline: "none" }} />
      </div>
      {/* <div> */}
      <div onClick={() => showVehicleDeleteModal(row)}>
        <Icon
          className="trash-icon"
          aria-label="Delete"
          alt="delete-icon"
          name="trash"
          size="medium"
          color="#000000"
          style={{ cursor: "pointer", outline: "none" }}
        />
      </div>
    </FlexRow>
  );
  const renderImage = (data, rowData) => {
    let showErrorIcon;
    if (rowData.upsell) {
      showErrorIcon = !rowData.validMMID || !rowData.orderType;
    } else {
      showErrorIcon = !rowData.validMMID || (rowData.upsell === false && !rowData.techRefID) || !rowData.contractItem || !rowData.orderType;
    }
    return (
      <>
        <div style={{ display: "flex", "margin-top": "2px" }}>
          <img alt="Error icon" src={FailedIcon} height="16px" width="16px" css={{ visibility: showErrorIcon ? "visible" : "hidden" }} />
        </div>
      </>
    );
  };
  return (
    <>
      <FlexRow>
        <LargeText style={{ marginRight: "5rem", marginTop: "0.5rem" }}>{data.vin}</LargeText>
        <InfoContainer>
          <LargeText style={{ paddingLeft: "0.5rem", width: "max-content" }}>
            {data.year}&nbsp;{data.make}&nbsp;{data.model}
          </LargeText>
        </InfoContainer>
      </FlexRow>
      <Header></Header>
      <FlexRow style={{ marginTop: "1rem" }}>
        <UltraMediumText css={{ marginTop: "0.5rem", marginRight: "2rem" }} eDSFont fontSize="18px" marginBottom="0.7rem">
          Shipping Information
        </UltraMediumText>
        <CustomDropdown
          width="30rem"
          value={shippingAddress.value}
          success={shippingAddress.success}
          error={shippingAddress.error}
          errorText={shippingAddress.errorText}
          onChange={(e) => handleFields("shippingAddress", e.target.value)}
        >
          <option disabled>Please select</option>
          {historicalAddress.map((data) => (
            <option key={generate()} value={data}>
              {data}
            </option>
          ))}
        </CustomDropdown>
      </FlexRow>
      <FlexRow style={{ marginTop: "1rem" }}>
        <UltraMediumText css={{ marginTop: "0.5rem", marginRight: "9rem" }} eDSFont fontSize="18px" marginBottom="0.7rem">
          Bundle
        </UltraMediumText>
        <CustomDropdown
          width="30rem"
          value={bundle.value}
          success={bundle.success}
          error={bundle.error}
          errorText={bundle.errorText}
          onChange={(e) => handleFields("bundle", e.target.value)}
        >
          <option disabled>Please select</option>
          {bundles.map((data) => (
            <option key={generate()} value={data}>
              {data}
            </option>
          ))}
        </CustomDropdown>
      </FlexRow>
      <FlexRow style={{ marginTop: "1rem" }}>
        <UltraMediumText css={{ marginTop: "0.5rem", marginRight: "2.5rem" }} eDSFont fontSize="18px" marginBottom="0.7rem">
          Hardware Selection
        </UltraMediumText>
        <FlexRow style={{ marginTop: "0.5rem" }}>
          <MediumText>{data.deviceType}</MediumText>
          <div onClick={() => setHardwareSelectionModal(true)}>
            <Icon
              aria-label="edit"
              alt="edit-icon"
              name="edit"
              size="medium"
              color="#000000"
              style={{ cursor: "pointer", outline: "none", marginTop: "0.1rem" }}
            />
          </div>
        </FlexRow>
        <Button
          aria-label="RECOMMENDED"
          // onClick={() => {
          //     setHardwareSelectionModal(true);
          // }}
          size="medium"
          role="button"
          css={{ margin: 0, borderRadius: "0.5rem", marginLeft: "1rem" }}
        >
          RECOMMENDED
        </Button>
      </FlexRow>
      <UltraMediumText css={{ marginTop: "1.25rem" }} eDSFont fontSize="18px" marginBottom="0.7rem">
        Item
      </UltraMediumText>
      <Spacer top="0.5rem" />
      <div tabIndex={0} aria-label="table">
        <GridComponent
          isExportEnabled={false}
          noDataMessage="No data available to display"
          rows={vehicleMMIDTableList}
          showActionIcon={showActionIcon}
          columns={EDIT_VEHICLE_MMID_COL}
          hideGlobalFilter={true}
          paginator={false}
          whiteSpace="normal"
          renderDataWithImage={renderImage}
        />
      </div>
      <Spacer top="10px" />
      <CustomSLButton secondary aria-label="Add Item">
        Add Item
      </CustomSLButton>
      <Spacer top="10px" />
      <FlexRow css={{ marginTop: "2rem" }}>
        <SLButton aria-label="Save" disabled={isSaveBtnDisabled}>
          Save
        </SLButton>
        <SLButton
          secondary
          aria-label="Cancel"
          onClick={() => {
            redirectToPrevPage();
          }}
        >
          Cancel
        </SLButton>
      </FlexRow>
      {hardawareSelectionModal && (
        <Modal
          role="dialog"
          ariaLabel="Hardware Selection"
          hideCloseButton={false}
          opened={true}
          disableOutsideClick
          css={StyledModal}
          closeButton={
            <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
              <div css={{ paddingLeft: "1.5rem" }}>
                <Title size="medium">Hardware Selection</Title>
              </div>
              <div onClick={closeHandler} style={{ marginRight: "1rem", marginTop: "0.3rem" }}>
                <Icon
                  aria-label="close"
                  alt="close-icon"
                  role="img"
                  name="close"
                  size="medium"
                  color="#000000"
                  style={{ cursor: "pointer", outline: "none" }}
                />
              </div>
            </FlexRow>
          }
        >
          <ModalBody css={{ padding: "16px 0 16px 16px !important" }}>
            <div style={{ marginTop: "1rem" }}>
              <MediumText>Please Select a Hardware.</MediumText>
              <MediumText style={{ marginTop: "1rem" }}>Recommendations</MediumText>
              <CustomDropdown
                width="30rem"
                ariaLabel="Recommendations"
                value={selectedRecommendation.value ? selectedRecommendation.value : "Please select Recommendation"}
                success={selectedRecommendation.success}
                error={selectedRecommendation.error}
                errorText={selectedRecommendation.errorText}
                onChange={(e) => handleFields("recommendation", e.target.value)}
              >
                <option disabled>Please select Recommendation</option>
                {recommendations.map((recommendation) => (
                  <option key={generate()}>{recommendation}</option>
                ))}
              </CustomDropdown>
              <MediumText style={{ marginTop: "1rem" }}>Reason For Changing</MediumText>
              <CustomDropdown
                width="30rem"
                value={selectedReason.value ? selectedReason.value : "Please select Reason"}
                success={selectedReason.success}
                error={selectedReason.error}
                errorText={selectedReason.errorText}
                onChange={(e) => handleFields("reason", e.target.value)}
              >
                <option disabled>Please select Reason</option>
                {reasons.map((reason) => (
                  <option key={generate()}>{reason}</option>
                ))}
              </CustomDropdown>
            </div>
            <FlexRow css={{ marginTop: "1rem", justifyContent: "start" }}>
              <Button
                css={{ marginLeft: "0.5rem", marginRight: "0.5rem", backgroundColor: "#fff", color: "#000" }}
                aria-label="Cancel"
                role="button"
                size="small"
                onClick={closeHandler}
              >
                Cancel
              </Button>
              <Button
                css={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
                primary
                aria-label="Confirm"
                role="button"
                size="small"
                disabled={isConfirmBtnDisabled}
                onClick={confirmEnableScreen}
              >
                Confirm
              </Button>
            </FlexRow>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}

export const EditVehicleDetailsMetadata = {
  name: "Edit Vehicle Details",
  id: "edit_vehicle_details",
  component: lazy(() => import("./EditDetails")),
  route: "/edit_vehicle_details",
};
