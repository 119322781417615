import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexColumn, FlexRow, Spacer, SmallText, SLNotification } from "../../components/core/utility";
import Icon from "@vds/icons";
import { CustomDropdown, CustomInput } from "./AddAVehicle";
import { C4SContext } from "../../context/c4sContext";
import { generate } from "shortid";
import _ from "lodash";

import { Loader } from "@vds/loaders";
import { TextArea } from "@vds/inputs";
import { MultiSelect } from "react-multi-select-component";
import styled from "styled-components";
import { Dialog } from "primereact/dialog";
//import { DialogModal } from "./AddVehicleMMIDModal";
import useOutsideClick from "../../customHook/use-outside-click";
import { ValidateVehicleAddress } from "./ValidateVehicleAddress";

export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;

export const DialogModal = styled(Dialog)`
  overflow-y: inherit !important;
  width: 50vw !important;
  .p-dialog-content {
    // height: 83vh !important;
    overflow-y: auto !important;
    padding: 0 1.5rem 0 1.5rem !important;
  }
  [data-testid~="modal-body"] {
    overflow-y: unset !important;
  }

  @media screen and (min-width: 1300px) and (max-width: 1500px) {
    width: 45vw !important;
  }

  @media screen and (min-width: 910px) and (max-width: 1025px) {
    width: 60vw !important;
    margin-left: 50px;
  }
  @media screen and (min-width: 1501px) {
    width: 650px !important;
    // max-height: 72vh !important;
  }
  @media screen and (min-width: 700px) and (max-width: 909px) {
    width: 70vw !important;
    margin-left: 50px;
  }
  // @media (max-height: 750px) {
  //   width: 70vw !important;
  // }
`;

export const CustomisedInput = styled(MultiSelect)`
  border: 0.0625rem solid #d8dada !important;
  border-bottom: 0.0625rem solid #000000 !important;
  font-family: Verizon-NHG-eTX;
  width: 100%;
  box-shadow: none !important;
  border-radius: 0;
  .dropdown-content {
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin: auto;
    position: absolute;
    z-index: 100000;
    border: unset !important;
  }
  .dropdown-container {
    border: unset !important;
  }
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active,
  .p-dropdown:focus,
  .p-dropdown:active {
    font-size: 8px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin: auto;
    position: relative;
    border: none;
    color: #000000 !important;
    background-color: transparent;
  }
`;

export const CustomNotification = styled(SLNotification)`
  & div {
    justify-content: flex-start;
    max-width: 100%;
    & span:first-child {
      flex: 20 1 0%;
    }
  }
`;

export const CustomisedNotification = css`
  & span:last-child {
    max-width: fit-content;
  }
`;

export const AddVehicleModal = ({ addVehicleModal, setAddVehicleModal }) => {
  const { isSourceCSP } = useContext(C4SContext);
  const [isLoading, setLoader] = useState(false);
  const [notification, setNotification] = useState(null);
  const [warning, setWarning] = useState(null);

  // new fields
  const [vinSearchInputValue, setVinSearchInputValue] = useState("");
  const [errValue, setErrValue] = useState("");

  const [address, setAddress] = useState({
    verified: false,
    addressByPass: null,
    locationFound: null,
    timezone: "",
    historicalAddress: { value: "", error: false, errorText: "", success: false },
    address1: { value: "", error: false, errorText: "Enter valid street address", success: false },
    address2: { value: "", error: false, errorText: "Enter address 2", success: false },
    city: { value: "", error: false, errorText: "Enter city", success: false },
    country: { value: null, options: [], error: false, errorText: "Please select country", success: false },
    state: { value: null, options: [], allStateOptions: null, error: false, errorText: "Please select state", success: false },
    zip: { value: "", error: false, errorText: "Enter zip code", success: false },
  });
  const [vehicleData, setVehicleData] = useState([]);
  let featureList = [];

  const handleClickOutside = () => {
    setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setAddVehicleModal(false);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  const onChangeSearchInputValue = (e) => {
    setVinSearchInputValue(e.target.value);
    //setDisableDate(!!e.target.value);
  };

  const notify = (content, type, isNotificationWithTimer = true) => {
    setNotification(
      <CustomNotification
        key={generate()}
        type={type}
        title={content}
        fullBleed={true}
        onCloseButtonClick={() => setNotification(null)}
        ref={clickRef}
      />
    );
    if (isNotificationWithTimer) {
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  return (
    <>
      <Spacer top="5px" />
      <DialogModal
        visible={true}
        onHide={() => setAddVehicleModal(false)}
        focusOnShow={true}
        opened={addVehicleModal}
        ariaLabel="Add Vehicle Modal"
        header={
          <FlexRow justifyContent="space-between" style={{ padding: "5px 15px 5px 15px" }}>
            <div>
              <Title size="small">Add Vehicle</Title>
            </div>
          </FlexRow>
        }
        //style={{ width: "65vw", height: "100%", overflowY: "auto" }}
        style={{ width: "65vw", overflowY: "auto", marginTop: "30px" }}
      >
        <ModalBody css={{ padding: "16px 0 16px 0 !important" }}>
          {!isSourceCSP.isSourceCSP && !notification && warning}
          {notification}
          {/* <Spacer top="1rem" /> */}
          <FlexRow css={{ padding: "5px !important" }}>
            <TextArea
              value={vinSearchInputValue}
              onChange={onChangeSearchInputValue}
              label="VIN"
              //required={false}
              //disabled={disableSearchInput}
              error={errValue !== ""}
              errorText={errValue}
              //tooltipTitle="Comma seperated values"
              //tooltipContent="eg: "
              minHeight="1X"
              width={"70%"}
              css={{ fontSize: "12px !important" }}
              //maxLength={2000}
            />
          </FlexRow>
          {/* <Spacer top="10px" /> */}
          <ValidateVehicleAddress
            address={address}
            setAddress={setAddress}
            vehicleData={vehicleData}
            showHistoricalAddressDropdown={true}
            setLoader={setLoader}
          />
          <FlexRow css={{ justifyContent: "end", alignItems: "flex-end" }}>
            <CustomSLButton primary aria-label="Save" onClick={() => setAddVehicleModal(false)}>
              Save
            </CustomSLButton>
          </FlexRow>
        </ModalBody>
      </DialogModal>
    </>
  );
};
