import React, { lazy, useContext, useState, useEffect } from "react";
import { Micro, Title } from "@vds/typography";
import { FlexRow, SmallText, Spacer } from "../../components/core/Utility/index";
import { UploadFile } from "./UploadFile";
import { Button } from "@vds/buttons";
import { CustomSLButton, MediumText, SLButton, SLNotification } from "../../components/core/utility";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { getCSPDetails } from "../../services/C4S Decommission - Replacement/getCSPDetails";

import { generate } from "shortid";
import { C4SContext } from "../../context/c4sContext";
import { getVehicleIntakeList } from "../../services/C4S Decommission - Replacement/getVehicleIntakeList";
import { handleAddPage } from "./utils";
import { VehicleListTableMetadata } from "./VehicleListTable";
import { AddAVehicleMetadata } from "./AddAVehicle";
import { AddHardwareMetadata } from "./AddHardware";
import styled from "styled-components";
import { Col, Grid, Row } from "@vds/grids";
import { InputNumber } from "primereact/inputnumber";
import saveFile from "../../assets/icons/save_blk.png";
import { Loader } from "@vds/loaders";
import { getPOSSUIRSData } from "../../services/C4S Decommission - Replacement/getPOSSUIRSData";
import { getPOSSUIRSReport } from "../../services/C4S Decommission - Replacement/getPOSSUIRSReport";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { DETAILED_MMID_POSSUIRS_COL } from "../../constants/DetailedPOSSUIRSConstants";
import { IndividualReturnMetadata } from "./IndividualReturn";
import { hasPrivilege } from "../../../utility/privileges";
import { Privileges } from "../../../constants/PrivilegeConstants";
import useOutsideClick from "../../customHook/use-outside-click";
import { Accordion, AccordionTab } from "primereact/accordion";
import ContractDetailsData from "./ContractDetailsData";
import { groupBy } from "lodash";
import VieweWorkTicketModal from "./ViewWorkTicketModal";

export const AccordionWrapper = styled.div`
  padding-top: 0.8rem;
  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link,
  .p-accordion .p-accordion-tab .p-accordion-header:hover .p-accordion-header-link,
  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    color: #000000;
    border: none;
    padding: 0.5rem;
    padding-left: 0;
    background: white;
    box-shadow: unset;
    border-radius: unset;
  }

  .p-accordion .p-accordion-content {
    padding: 0rem;
    border: none;
  }
  .p-accordion-header-text {
    font-weight: 700;
    font-family: Verizon-NHG-eDS;
    font-size: 1.3rem;
  }
`;
const possuiresData = {
  ordered: "1",
  pendingShipmentHardware: "1",
  pendingShipmentAccessories: "0",
  shippedHardware: "1",
  shippedAccessories: "1",
  scheduled: "0",
  unscheduled: "0",
  installed: "0",
  remaining: "0",
  selfInstalled: "0",
  returnHardware: "0",
  returnAccessory: "0",
};

const POSSUIRSContainer = styled.div`
  border: 1px solid #e2e4e4;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  @media (max-width: 767px) and (min-width: 500px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
`;

const POSSUIRSInput = styled(InputNumber)`
  padding-right: 5px;
  .p-inputtext {
    border-left: solid 1px #ced4da !important;
  }
`;

const POSSUIRSBlock = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
`;

const POSSUIRSBox = styled.div((props) => {
  const style = {
    border: "1px solid #e2e4e4",
    "border-radius": "0px",
    padding: "2px 2px 2px 20px",
    display: "flex",
    "align-items": "center",
    ...props,
  };
  delete style.children;
  return style;
});

const VehicleIntake = (props) => {
  const [accountDetails, setAccountDetails] = useState([]);
  let productData = groupBy(props?.cspData?.data?.data?.Product, "BundleId");
  const [productDetails] = useState(Object.values(productData));
  const { isButtonDisabled, cspDetails, notification, recordsUploaded, isSourceCSP, limitWTCreation, possuir, source } = useContext(C4SContext);
  let [downloadTemplateURL, setDownloadTemplateURL] = useState("");
  let [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(false);
  const [POSSUIRSData, setPOSSUIRSData] = useState(possuiresData);
  const [viewWorkTicketModal, setViewWorkTicketModal] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const handleClickOutside = () => {
    notification.setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    getCSPAcccountDetails();
  }, []);

  const getPOSSUIRS = async () => {
    setLoader(true);
    let response = await getPOSSUIRSData(props.AccountID);
    if (response?.status === 200 && response?.data) {
      setLoader(false);
      setPOSSUIRSData(response.data.data);
    } else {
      setLoader(false);
      notification.setNotification(
        <SLNotification
          key={generate()}
          type="error"
          title="We are currently experiencing issues while fetching POSSUIRS, please try again"
          fullBleed={true}
          ref={clickRef}
        />
      );
    }
  };

  /**
   * Fetches CSP account details and updates the possuire data and session storage.
   */
  const cspAccountDetailsCall = async () => {
    updateNewrelicStats();
    setLoader(true);
    let response = await getCSPDetails(props?.AccountID, props?.ID, props?.userId);
    if (response.status === 200 && response?.data?.data) {
      let data = response.data.data;
      sessionStorage.setItem("CSPDetails", JSON.stringify({ ...data, source: source }));
      sessionStorage.setItem("Name", JSON.stringify(data.firstName + " " + data.lastName));
      possuir.setPossuir(data.CSP.length ? data.CSP.filter((csp) => csp.DefaultCSP === "1")[0]?.possuir : {});
      let possuirContractsDetail = data?.CSP?.length ? data?.CSP?.filter((csp) => csp?.DefaultCSP === "1")[0]?.possuir?.possuirContractsDetail : {};
      updatePossuirs(possuirContractsDetail);
    } else {
      notification.setNotification(
        <SLNotification key={generate()} type="error" title="Unable to fetch the csp details due to some error" fullBleed={true} ref={clickRef} />
      );
    }
    setLoader(false);
  };
  const getPOSSUIRSReportCall = async () => {
    setLoader(true);
    let refreshPayload = {
      FromDateandTime: "",
      ToDateandTime: "",
      CRMRefID: props?.cspData?.data?.data?.CSP[0]?.CRM_Ref_ID ?? "",
    };
    let response = await getPOSSUIRSReport(refreshPayload);
    if (response?.status === 200) {
      cspAccountDetailsCall();
    } else {
      notification.setNotification(
        <SLNotification
          key={generate()}
          type="error"
          title="We are currently experiencing issue while fetching POSSUIRS Report, please try again"
          fullBleed={true}
          ref={clickRef}
        />
      );
    }
    setLoader(false);
  };

  const getCSPAcccountDetails = async () => {
    updateNewrelicStats();
    let downloadUrl = "";
    if (props.cspData) {
      downloadUrl = handleDownloadTemplate(process.env.REACT_APP_ENV);
      setDownloadTemplateURL(downloadUrl);
      setAccountDetails(props.cspData);
    } else {
      isButtonDisabled.setIsButtonDisabled(true);
      notification.setNotification(
        <SLNotification
          key={generate()}
          type="error"
          title="We are currently experiencing issues, please try again"
          fullBleed={true}
          ref={clickRef}
        />
      );
    }
    let possuirContractsDetail = possuir.possuir?.possuirContractsDetail;
    updatePossuirs(possuirContractsDetail);
  };
  const updatePossuirs = (possuirContractsDetail) => {
    const possuiresData = {
      ordered: formattedData(possuirContractsDetail?.OrderedQty),
      pendingShipmentHardware: formattedData(possuirContractsDetail?.PendingQty),
      pendingShipmentAccessories: formattedData(possuirContractsDetail?.PendingShipAccessQty),
      shippedHardware: formattedData(possuirContractsDetail?.ShippedQty),
      shippedAccessories: formattedData(possuirContractsDetail?.ShippedAccessoryQty),
      scheduled: formattedData(possuirContractsDetail?.ScheduledQty),
      unscheduled: formattedData(possuirContractsDetail?.UnScheduledQty),
      installed: formattedData(possuirContractsDetail?.InstalledQty),
      remaining: formattedData(possuirContractsDetail?.RemainingQty),
      selfInstalled: formattedData(possuirContractsDetail?.SelfInstalledQty),
      returnHardware: formattedData(possuirContractsDetail?.ReturnHardwareQty),
      returnAccessory: formattedData(possuirContractsDetail?.ReturnAccessoryQty),
    };
    setPOSSUIRSData(possuiresData);
  };
  const getVehicleList = () => {
    handleAddPage(VehicleListTableMetadata.id);
  };
  const formattedData = (data) => {
    return data !== undefined && data !== null ? data : "--";
  };

  const handleDownloadTemplate = (env) => {
    let countryBasedTemplateName = "";
    countryBasedTemplateName = "Vehicle_Intake_Template.csv";
    switch (env) {
      case "localscheduler":
        return `http://superlibra-dev.it-vzc.com/workTicket/en/${countryBasedTemplateName}`;
      case "dev":
        return `https://superlibra-dev.it-vzc.com/workTicket/en/${countryBasedTemplateName}`;
      case "qa":
        return `https://superlibra-qa.it-vzc.com/workTicket/en/${countryBasedTemplateName}`;
      case "uat":
        return `https://superlibra-uat.it-vzc.com/workTicket/en/${countryBasedTemplateName}`;
      case "prod":
        return `https://superlibra.it-vzc.com/workTicket/en/${countryBasedTemplateName}`;
      default:
    }
  };

  const handlePOSSUIRSInputChange = (e, name) => {};

  const savePOSSUIRS = (name) => {
    //console.log("name", name);
  };

  const GridBlock = (props) => {
    return (
      <POSSUIRSBlock>
        <POSSUIRSBox css={{ "justify-content": "space-between" }}>
          <Micro bold>{props.title}</Micro>
          {props.isEditable ? (
            <div
              css={{
                cursor: "pointer",
                width: "25px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
              onClick={(e) => savePOSSUIRS(props.title)}
            >
              <img alt="Error icon" src={saveFile} height="16px" width="16px" />
            </div>
          ) : (
            <></>
          )}
        </POSSUIRSBox>
        <POSSUIRSBox>
          {props.isEditable ? (
            <POSSUIRSInput id={props.title} value={props.data} onValueChange={(e) => handlePOSSUIRSInputChange(e, props.title)} />
          ) : (
            <Micro>{props.data}</Micro>
          )}
        </POSSUIRSBox>
      </POSSUIRSBlock>
    );
  };

  const reviewWorkTicketModal = () => {
    setViewWorkTicketModal(true);
  };

  return (
    <>
      <Loader active={isLoading} fullscreen />
      {viewWorkTicketModal && <VieweWorkTicketModal viewWorkTicketModal={viewWorkTicketModal} setViewWorkTicketModal={setViewWorkTicketModal} />}
      <Spacer top="1rem" />
      <Title>Vehicle Intake</Title>
      <Spacer top="10px" />
      {isSourceCSP.isSourceCSP && (
        <SmallText>Add a vehicle(s) or hardware. You can download CSV template to upload multiple vehicles and/or upload a file here.</SmallText>
      )}
      {!isSourceCSP.isSourceCSP && <SmallText>Add hardware for customer cases.</SmallText>}
      <Spacer top="1rem" />
      <FlexRow>
        {isSourceCSP.isSourceCSP && (
          <>
            <CustomSLButton
              size="large"
              secondary
              aria-label="Download Template"
              role="button"
              href={downloadTemplateURL}
              aria-disabled={limitWTCreation.limitWTCreation || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)}
              disabled={limitWTCreation.limitWTCreation || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)}
              onClick={() => notification.setNotification(null)}
            >
              Download Template
            </CustomSLButton>
            <UploadFile getVehicleList={getVehicleList} />
            <CustomSLButton
              size="large"
              aria-label="Add a Vehicle"
              role="button"
              aria-disabled={
                isButtonDisabled.isButtonDisabled || limitWTCreation.limitWTCreation || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)
              }
              disabled={
                isButtonDisabled.isButtonDisabled || limitWTCreation.limitWTCreation || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)
              }
              onClick={() => {
                handleAddPage(AddAVehicleMetadata.id);
                notification.setNotification(null);
              }}
            >
              Add a Vehicle
            </CustomSLButton>
          </>
        )}
        <CustomSLButton
          size="large"
          aria-label="Add Hardware"
          role="button"
          aria-disabled={
            isButtonDisabled.isButtonDisabled || limitWTCreation.limitWTCreation || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)
          }
          disabled={
            isButtonDisabled.isButtonDisabled || limitWTCreation.limitWTCreation || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)
          }
          onClick={() => {
            notification.setNotification(null), handleAddPage(AddHardwareMetadata.id);
          }}
        >
          Add Hardware
        </CustomSLButton>
        <CustomSLButton size="large" aria-label="Review Work Ticket" role="button" onClick={() => reviewWorkTicketModal()}>
          Review Work Ticket
        </CustomSLButton>
        {!isSourceCSP.isSourceCSP && (
          <CustomSLButton
            size="large"
            secondary
            disabled={isButtonDisabled.isButtonDisabled || limitWTCreation.limitWTCreation}
            onClick={() => {
              notification.setNotification(null), handleAddPage(IndividualReturnMetadata.id);
            }}
          >
            Return
          </CustomSLButton>
        )}
      </FlexRow>
      <FlexRow>
        {isSourceCSP.isSourceCSP && (
          <>
            <div css={{ paddingRight: "1.5rem", margin: "2px" }}>
              <AccordionWrapper>
                <Accordion activeIndex={0} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
                  <AccordionTab aria-label="Contract Details" header="Contract Details">
                    {productDetails && productDetails.length ? (
                      <Spacer>
                        <ContractDetailsData data={productDetails} />
                      </Spacer>
                    ) : (
                      <Spacer top="10px">
                        <MediumText>No Items</MediumText>
                      </Spacer>
                    )}
                  </AccordionTab>
                </Accordion>
              </AccordionWrapper>
            </div>
          </>
        )}
      </FlexRow>
      <FlexRow marginTop="20px">
        {isSourceCSP.isSourceCSP && (
          <>
            <Spacer top="30px" />
            <div css={{ paddingRight: "1.5rem", margin: "2px" }}>
              <Title ariaLabel="POSSUIRS">POSSUIRS</Title>
            </div>
            <Button
              size="small"
              secondary
              aria-label="Refresh"
              role="button"
              aria-disabled={limitWTCreation.limitWTCreation || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)}
              disabled={limitWTCreation.limitWTCreation || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)}
              onClick={getPOSSUIRSReportCall}
            >
              Refresh
            </Button>
            {POSSUIRSData && Object.keys(POSSUIRSData).length ? (
              <Spacer top="20px">
                <POSSUIRSContainer>
                  <GridBlock title="Ordered" data={POSSUIRSData.ordered} />
                  <GridBlock title="Pending Shipment Hardware" data={POSSUIRSData.pendingShipmentHardware} />
                  <GridBlock title="Pending Shipment Accessories" data={POSSUIRSData.pendingShipmentAccessories} />
                  <GridBlock title="Shipped Hardware" data={POSSUIRSData.shippedHardware} />
                  <GridBlock title="Shipped Accessories" data={POSSUIRSData.shippedAccessories} />
                  <GridBlock title="Scheduled" data={POSSUIRSData.scheduled} />
                  <GridBlock title="Unscheduled" data={POSSUIRSData.unscheduled} />
                  <GridBlock title="Installed" data={POSSUIRSData.installed} isEditable={false} />
                  <GridBlock title="Remaining" data={POSSUIRSData.remaining} />
                  <GridBlock title="Self-Installed" data={POSSUIRSData.selfInstalled} isEditable={false} />
                  <GridBlock title="Return Hardware" data={POSSUIRSData.returnHardware} />
                  <GridBlock title="Return Accessory" data={POSSUIRSData.returnAccessory} />
                </POSSUIRSContainer>
              </Spacer>
            ) : (
              <Spacer top="20px">
                <MediumText>No Items</MediumText>
              </Spacer>
            )}
          </>
        )}
        {isSourceCSP.isSourceCSP && (
          <>
            <Spacer top="10px" />
            {/* Detailed MMID POSSUIRS */}
            <GridComponent
              isExportEnabled={false}
              noDataMessage="No data available to display"
              rows={possuir.possuir?.PossuirContractLine || []}
              showActionIcon={false}
              columns={DETAILED_MMID_POSSUIRS_COL}
              hideGlobalFilter={true}
              paginator={false}
            />
          </>
        )}
      </FlexRow>
    </>
  );
};
export default VehicleIntake;

export const VehicleIntakeMetadata = {
  name: "Vehicle Intake",
  id: "vehicle_intake",
  component: lazy(() => import("./VehicleIntake")),
  route: "/vehicle_intake",
};
