import React, { useEffect, useState, useContext } from "react";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import Icon from "@vds/icons";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexRow, SmallText, Spacer } from "../../components/core/utility";
import { C4SContext } from "../../context/c4sContext";
import { createWorkticket } from "../../services/C4S Decommission - Replacement/createWorkticket";
import { generate } from "shortid";
import { Loader } from "@vds/loaders";
import { Table, TableHead, TableBody, TableRow, Cell, TableHeader } from "@vds/tables";
import edit from "../../assets/icons/edit.png";
import moment from "moment";
import { handleAddPage } from "./utils";
import { EditVehicleDetailsMetadata } from "./EditDetails";
const CustomTableHeader = css`
  margin-bottom: 0px;
  table-layout: fixed;
  thead {
    border-bottom: 2px solid #000000;
    margin-bottom: 3px;
    th {
      padding: 1rem 0rem 0.75rem 0rem;
    }
  }
  td {
    padding: 5px 2px 5px 0px;
  }
`;

export const StyledModal = css`
  max-height: 99vmin;
  width: 100%;
  height: 80%;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  @media (max-width: 767px) {
    width: 700px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;
const CustomTableHeader2 = css`
  margin-bottom: 0px;
  table-layout: fixed;
  thead {
    border-bottom: none;
    margin-bottom: 1px;
    th {
      padding: 0;
    }
  }
  td {
    padding: 2px 2px 2px 0px;
  }
`;

const mockData = [
  {
    bundleId: "QL-20717528",
    bundleDescription: "Device Upfront | VTU + PTO (QL-20717528)  ( 2 / 10 )",
    bundleVin: [
      {
        id: 2252,
        vin: "3VWRK69M44M051589",
        vinCount: 1,
        status: "OPEN",
        year: 2004,
        make: "Volkswagen",
        model: "Jetta",
        errorYMM: false,
        installType: "VMI",
        addressOne: "11589 TUXFORD ST",
        addressTwo: "",
        city: "SUN VALLEY",
        stateCode: "CA",
        zipCode: "91352",
        country: "USA",
        timezone: "Central Standard Time",
        locationFound: true,
        addressByPass: "NO",
        salesOrg: "9001 VZC Fleet USA LLC",
        currencyCode: "USD",
        division: "02",
        distributionChannel: "02",
        deviceType: "Xirgo FGUS02",
        priority: 1,
        materials: [
          {
            id: 23197,
            vmfType: "ZHWA",
            upsell: false,
            techRefId: "0002211497.0005606070",
            vmfSapIdVal: "1400000280",
            vmfSapId: 25674,
            vmfSapName: "Subscription | Vehicle Tracking",
            materialId: "1100001092",
            materialName: "VTU | Xirgo 6383 | VZW ELD FGUS3",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "VTUParts",
          },
          {
            id: 23198,
            vmfType: "ZHWA",
            upsell: false,
            techRefId: "0002211497.0005606070",
            vmfSapIdVal: "1400000280",
            vmfSapId: 25674,
            vmfSapName: "Subscription | Vehicle Tracking",
            materialId: "1100000641",
            materialName: "Modified CBL-0056: Fleet cable",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "VTUCableParts",
          },
          {
            id: 23199,
            vmfType: "ZHWA",
            upsell: false,
            techRefId: "0002211497.0005606073",
            vmfSapIdVal: "1400000304",
            vmfSapId: 25746,
            vmfSapName: "Subscription | PTO/Digital Input",
            materialId: "1100001056",
            materialName: "Pwr Y-Cbl Kit | 9 Pin - Green | LMU 3640",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "Feature | PTO/Digital Input",
          },
        ],
      },
      {
        id: 2253,
        vin: "5FNYF4H52FB013352",
        vinCount: 1,
        status: "OPEN",
        year: 2015,
        make: "Honda Pilot",
        model: "EX-L 4WD",
        errorYMM: false,
        installType: "VMI",
        addressOne: "11589 TUXFORD ST",
        addressTwo: "",
        city: "SUN VALLEY",
        stateCode: "CA",
        zipCode: "91352",
        country: "USA",
        timezone: "Central Standard Time",
        locationFound: true,
        addressByPass: "NO",
        salesOrg: "9001 VZC Fleet USA LLC",
        currencyCode: "USD",
        division: "02",
        distributionChannel: "02",
        deviceType: "Xirgo FGUS02",
        bundleId: "QL-20717528",
        bundleDescription: "Device Upfront | VTU + PTO  (QL-20717528)  (2/10)",
        priority: 1,
        materials: [
          {
            id: 23197,
            vmfType: "ZHWA",
            upsell: false,
            techRefId: "0002211497.0005606070",
            vmfSapIdVal: "1400000280",
            vmfSapId: 25674,
            vmfSapName: "Subscription | Vehicle Tracking",
            materialId: "1100001092",
            materialName: "VTU | Xirgo 6383 | VZW ELD FGUS3",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "VTUParts",
          },
          {
            id: 23198,
            vmfType: "ZHWA",
            upsell: false,
            techRefId: "0002211497.0005606070",
            vmfSapIdVal: "1400000280",
            vmfSapId: 25674,
            vmfSapName: "Subscription | Vehicle Tracking",
            materialId: "1100000641",
            materialName: "Modified CBL-0056: Fleet cable",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "VTUCableParts",
          },
          {
            id: 23199,
            vmfType: "ZHWA",
            upsell: false,
            techRefId: "0002211497.0005606073",
            vmfSapIdVal: "1400000304",
            vmfSapId: 25746,
            vmfSapName: "Subscription | PTO/Digital Input",
            materialId: "1100001056",
            materialName: "Pwr Y-Cbl Kit | 9 Pin - Green | LMU 3640",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "Feature | PTO/Digital Input",
          },
        ],
      },
    ],
  },
  {
    bundleId: "QL-20717527",
    bundleDescription: "Device Upfront | VTU + Privacy (QL-20717527)  ( 0 / 10 )",
    bundleVin: [],
  },
  {
    bundleId: "QL-20717511",
    bundleDescription: "Bundled Plan | VTU   (QL-20717511)  ( 3 / 10 )",
    bundleVin: [
      {
        id: 2254,
        vin: "WBAFR1C58BC260268",
        vinCount: 1,
        status: "OPEN",
        year: 2011,
        make: "BMW",
        model: "528i",
        errorYMM: false,
        installType: "VMI",
        addressOne: "11589 TUXFORD ST",
        addressTwo: "",
        city: "SUN VALLEY",
        stateCode: "CA",
        zipCode: "91352",
        country: "USA",
        timezone: "Central Standard Time",
        locationFound: true,
        addressByPass: "NO",
        salesOrg: "9001 VZC Fleet USA LLC",
        currencyCode: "USD",
        division: "02",
        distributionChannel: "02",
        deviceType: "Xirgo FGUS02",
        priority: 1,
        materials: [
          {
            id: 23197,
            vmfType: "ZHWS",
            upsell: false,
            techRefId: "0002211497.0005606062",
            vmfSapIdVal: "1400000030",
            vmfSapId: 25141,
            vmfSapName: "Vehicle Tracking Subscription",
            materialId: "1100001092",
            materialName: "VTU | Xirgo 6383 | VZW ELD FGUS3",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "VTUParts",
          },
          {
            id: 23198,
            vmfType: "ZHWA",
            upsell: false,
            techRefId: "0002211497.0005606062",
            vmfSapIdVal: "1400000030",
            vmfSapId: 25141,
            vmfSapName: "Vehicle Tracking Subscription",
            materialId: "1100000641",
            materialName: "Modified CBL-0056: Fleet cable",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "VTUCableParts",
          },
          {
            id: 23199,
            vmfType: "ZHWA",
            upsell: false,
            techRefId: "0002211497.0005606064",
            vmfSapIdVal: "1400000031",
            vmfSapId: 25152,
            vmfSapName: "Panic Button Subscription",
            materialId: "1100000044",
            materialName: "Xirgo Panic Kit",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "Feature | Panic Button / Privacy",
          },
        ],
      },
      {
        id: 2255,
        vin: "1GYEC63N76R148863",
        vinCount: 1,
        status: "OPEN",
        year: 2006,
        make: "Cadillac",
        model: "Escalade 2WD",
        errorYMM: false,
        installType: "VMI",
        addressOne: "4907 INTERNATIONAL BLVD",
        addressTwo: "",
        city: "FREDERICK",
        stateCode: "MA",
        zipCode: "21703",
        country: "USA",
        timezone: "Central Standard Time",
        locationFound: true,
        addressByPass: "NO",
        salesOrg: "9001 VZC Fleet USA LLC",
        currencyCode: "USD",
        division: "02",
        distributionChannel: "02",
        deviceType: "Xirgo FGUS03",
        priority: 1,
        materials: [
          {
            id: 23197,
            vmfType: "ZHWS",
            upsell: false,
            techRefId: "0002211497.0005606062",
            vmfSapIdVal: "1400000030",
            vmfSapId: 25141,
            vmfSapName: "Vehicle Tracking Subscription",
            materialId: "1100001092",
            materialName: "VTU | Xirgo 6383 | VZW ELD FGUS3",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "VTUParts",
          },
          {
            id: 23198,
            vmfType: "ZHWA",
            upsell: false,
            techRefId: "0002211497.0005606062",
            vmfSapIdVal: "1400000030",
            vmfSapId: 25141,
            vmfSapName: "Vehicle Tracking Subscription",
            materialId: "1100000641",
            materialName: "Modified CBL-0056: Fleet cable",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "VTUCableParts",
          },
          {
            id: 23199,
            vmfType: "ZHWA",
            upsell: false,
            techRefId: "0002211497.0005606064",
            vmfSapIdVal: "1400000031",
            vmfSapId: 25152,
            vmfSapName: "Panic Button Subscription",
            materialId: "1100000044",
            materialName: "Xirgo Panic Kit",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "Feature | Panic Button / Privacy",
          },
        ],
      },
      {
        id: 2256,
        vin: "1LNFM97V6WY737164",
        vinCount: 1,
        status: "OPEN",
        year: 1998,
        make: "Lincoln",
        model: "Continental",
        errorYMM: false,
        installType: "VMI",
        addressOne: "4907 INTERNATIONAL BLVD",
        addressTwo: "",
        city: "FREDERICK",
        stateCode: "MA",
        zipCode: "21703",
        country: "USA",
        timezone: "Central Standard Time",
        locationFound: true,
        addressByPass: "NO",
        salesOrg: "9001 VZC Fleet USA LLC",
        currencyCode: "USD",
        division: "02",
        distributionChannel: "02",
        deviceType: "Xirgo FGUS03",
        priority: 1,
        materials: [
          {
            id: 23197,
            vmfType: "ZHWS",
            upsell: false,
            techRefId: "0002211497.0005606062",
            vmfSapIdVal: "1400000030",
            vmfSapId: 25141,
            vmfSapName: "Vehicle Tracking Subscription",
            materialId: "1100001092",
            materialName: "VTU | Xirgo 6383 | VZW ELD FGUS3",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "VTUParts",
          },
          {
            id: 23198,
            vmfType: "ZHWA",
            upsell: false,
            techRefId: "0002211497.0005606062",
            vmfSapIdVal: "1400000030",
            vmfSapId: 25141,
            vmfSapName: "Vehicle Tracking Subscription",
            materialId: "1100000641",
            materialName: "Modified CBL-0056: Fleet cable",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "VTUCableParts",
          },
          {
            id: 23199,
            vmfType: "ZHWA",
            upsell: false,
            techRefId: "0002211497.0005606064",
            vmfSapIdVal: "1400000031",
            vmfSapId: 25152,
            vmfSapName: "Panic Button Subscription",
            materialId: "1100000044",
            materialName: "Xirgo Panic Kit",
            validMMID: true,
            quantityATP: null,
            dateATP: null,
            type: "HARDWARE",
            orderType: "Initial Sale",
            materialHwsType: "Feature | Panic Button / Privacy",
          },
        ],
      },
    ],
  },
];
function sortArrayOfObjects(arr, key) {
  return arr.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
}
const sortedArray = sortArrayOfObjects(mockData, "bundleId");
sortedArray.forEach((item) => {
  const bundle = item.bundleVin.map((i) => {
    return i.addressOne;
  });
  const uniqueArr = [...new Set(bundle)];
  const newDta = uniqueArr.map((u) => {
    const bund = item.bundleVin.filter((d) => {
      return d.addressOne === u;
    });
    return bund;
  });

  item.bundleVin = newDta;
});
const ViewWorkTicketModal = ({ viewWorkTicketModal, setViewWorkTicketModal }) => {
  const { cspDetails, notification, source } = useContext(C4SContext);
  const [isLoading, setLoader] = useState(false);
  const [materialList, setMaterialList] = useState([]);
  const [vinList, setVinList] = useState(sortedArray);

  useEffect(() => {
    let response = {
      statusCode: "200",
      message: "Success",
      data: [
        {
          materialId: "1100001092",
          materialName: "VTU | Xirgo 6383 | VZW ELD FGUS3",
          requestedQuanity: "5",
          atpQuantity: "5",
          atpDate: "20250218",
        },
        {
          materialId: "1100000641",
          materialName: "Modified CBL-0056: Fleet cable",
          requestedQuanity: "5",
          atpQuantity: "5",
          atpDate: "20250218",
        },
        {
          materialId: "1100000044",
          materialName: "Xirgo Panic Kit",
          requestedQuanity: "3",
          atpQuantity: "0",
          atpDate: null,
        },
        {
          materialId: "1100001056",
          materialName: "Pwr Y-Cbl Kit | 9 Pin - Green | LMU 3640",
          requestedQuanity: "2",
          atpQuantity: "2",
          atpDate: "20250218",
        },
      ],
    };
    setMaterialList(response.data);
  }, []);

  // useEffect(() => {
  //   const sortedArray = sortArrayOfObjects(mockkData, "bundleId");
  //   sortedArray.forEach((item) => {
  //     const bundle = item.bundleVin.map((i) => {
  //       return i.addressOne;
  //     });
  //     const uniqueArr = [...new Set(bundle)];
  //     const newDta = uniqueArr.map((u) => {
  //       const bund = item.bundleVin.filter((d) => {
  //         return d.addressOne === u;
  //       });
  //       return bund;
  //     });

  //     item.bundleVin = newDta;
  //   });

  //   setVinList(sortedArray);

  // }, []);

  return (
    <>
      <Loader active={isLoading} fullscreen />
      <Modal
        role="dialog"
        ariaLabel="Review Work Ticket"
        hideCloseButton={false}
        opened={viewWorkTicketModal}
        disableOutsideClick
        css={StyledModal}
        closeButton={
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <div>
              <Title size="medium">Review Work Ticket</Title>
            </div>
            <div
              onClick={() => {
                setViewWorkTicketModal(false);
              }}
              style={{ marginRight: "1rem", marginTop: "0.3rem" }}
            >
              <Icon
                aria-label="close"
                alt="close-icon"
                role="img"
                name="close"
                size="medium"
                color="#000000"
                style={{ cursor: "pointer", outline: "none" }}
              />
            </div>
          </FlexRow>
        }
      >
        <ModalBody css={{ paddingLeft: "0", paddingRight: "0", marginTop: "0.5rem" }}>
          {/* List of material code start*/}
          <Table tabIndex={0} role="table" topLine="none" css={CustomTableHeader2}>
            <TableHead tabIndex={0} role="columnheader">
              <TableHeader tabIndex={0} role="columnheader" width="15%">
                <SmallText bold>MMID</SmallText>
              </TableHeader>
              <TableHeader tabIndex={0} role="columnheader" width="32%">
                <SmallText bold>Item Description</SmallText>
              </TableHeader>
              <TableHeader tabIndex={0} role="columnheader" width="20%">
                <SmallText bold>Request Quantity</SmallText>
              </TableHeader>
              <TableHeader tabIndex={0} role="columnheader" width="17%">
                <SmallText bold> ATP Quantity </SmallText>
              </TableHeader>
              <TableHeader tabIndex={0} role="columnheader" width="15%">
                <SmallText bold> ATP Date </SmallText>
              </TableHeader>
            </TableHead>
          </Table>
          <div css={{ overflowY: "scroll" }}>
            <Loader active={isLoading} fullscreen={false} />
            <Table role="table" topLine="none" striped={false} css={CustomTableHeader2}>
              <TableBody role="rowgroup">
                {materialList.map((material, index) => {
                  return (
                    <TableRow role="row" key={material.materialId + index}>
                      <Cell tabIndex={0} role="cell" width="15%">
                        <SmallText>{material.materialId}</SmallText>
                      </Cell>
                      <Cell tabIndex={0} role="cell" width="32%">
                        <SmallText>{material.materialName}</SmallText>
                      </Cell>
                      <Cell tabIndex={0} role="cell" width="20%">
                        <SmallText>{material.requestedQuanity}</SmallText>
                      </Cell>
                      <Cell tabIndex={0} role="cell" width="17%">
                        <SmallText>{material.atpQuantity}</SmallText>
                      </Cell>
                      <Cell tabIndex={0} role="cell" width="15%">
                        <SmallText>{material.atpDate === null ? "" : moment(material.atpDate).format("MMM DD, YYYY")}</SmallText>
                      </Cell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <FlexRow css={{ justifyContent: "start", alignItems: "flex-start" }}>
            <CustomSLButton primary aria-label="Save" onClick={() => {}}>
              Create Work Ticket
            </CustomSLButton>
          </FlexRow>

          <Table role="table" topLine="none" striped={false} css={CustomTableHeader}>
            <TableBody role="rowgroup">
              {vinList.map((data) => {
                return (
                  <>
                    <FlexRow css={{ backgroundColor: "darkgray", padding: "8px 0 5px 3px", width: "440%", fontSize: "16px" }}>
                      <div>{data.bundleDescription}</div>
                    </FlexRow>
                    {data.bundleVin.length === 0 && <Spacer top="20px" />}
                    {data.bundleVin.map((vin, i) => {
                      return (
                        <>
                          <FlexRow css={{ marginTop: "8px", fontSize: "13px", fontWeight: "600", width: "300%" }} key={vin.id}>
                            <div>
                              Location: {vin[0].addressOne} {vin[0].addressTwo} {vin[0].city} {vin[0].stateCode} {vin[0].zipCode}
                            </div>
                          </FlexRow>
                          {vin.map((td, j) => {
                            return (
                              <TableRow role="row" key={td.id}>
                                <Cell tabIndex={0} role="cell" width="5%">
                                  <SmallText>
                                    <b>Vehicle:</b> {"  "}
                                    <img
                                      style={{ width: "15px", cursor: "pointer", marginRight: "20px" }}
                                      alt="edit-icon"
                                      aria-label="Edit Icon"
                                      src={edit}
                                      aria-controls="overlay_panel"
                                      onClick={() => {
                                        notification.setNotification(null);
                                        handleAddPage(EditVehicleDetailsMetadata.id, { data: td, description: data.bundleDescription });
                                        setViewWorkTicketModal(false);
                                      }}
                                    />
                                  </SmallText>
                                </Cell>
                                <Cell tabIndex={0} role="cell" width="10%">
                                  <SmallText>
                                    <b>{td?.vin}</b>
                                  </SmallText>
                                  {td.materials.map((mat) => {
                                    return <SmallText key={mat.id}>{mat.materialId}</SmallText>;
                                  })}
                                </Cell>
                                <Cell tabIndex={0} role="cell" width="45%">
                                  <SmallText>
                                    <b>
                                      {" "}
                                      {td.year} {td.make} {td.model}
                                    </b>
                                  </SmallText>
                                  {td.materials.map((mat) => {
                                    return <SmallText key={mat.id}>{mat.materialName}</SmallText>;
                                  })}
                                </Cell>
                                <Cell tabIndex={0} role="cell" width="37%">
                                  <SmallText>
                                    <b>Recommended: {td.deviceType}</b>
                                  </SmallText>
                                  {td.materials.map((mat) => {
                                    return <SmallText key={mat.id}>{mat.materialHwsType}</SmallText>;
                                  })}
                                </Cell>
                              </TableRow>
                            );
                          })}
                        </>
                      );
                    })}
                  </>
                );
              })}
            </TableBody>
          </Table>
          {/* </FlexRow> */}
        </ModalBody>
        {/* <ModalFooter>
          <FlexRow style={{ paddingTop: "2rem" }}>
            <CustomSLButton
              secondary
              aria-label="Cancel"
              role="button"
              onClick={() => {
                setViewWorkTicketModal(false);
              }}
            >
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalFooter> */}
      </Modal>
    </>
  );
};
export default ViewWorkTicketModal;
