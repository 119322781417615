import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const ADD_VEHICLE_MMID_COL = [
  {
    key: "",
    value: "image",
    containsImage: true,
    style: { minWidth: "25px", maxWidth: "25px" },
  },
  {
    key: "MMID",
    value: "materialId",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Item Description",
    value: "itemDescription",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "Type",
    value: "displayType",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: "150px",
  },
  {
    key: "Order Type",
    value: "orderType",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },

  {
    key: "Tech Ref ID",
    value: "techRefID",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Contract Item",
    value: "contractItem",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "Contract Override",
    value: "upsellForUI",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
];

export const EDIT_VEHICLE_MMID_COL = [
  ...ADD_VEHICLE_MMID_COL,
  {
    key: "Priority",
    value: "priority",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
];
