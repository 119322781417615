import React, { lazy, useEffect, useMemo, useState, useContext } from "react";
import { Grid, Row, Col } from "@vds/grids";
import { SLGridLayout, SLRowLayout, FlexColumn, FlexRow, MediumText, SmallText, Spacer, UltraMediumText } from "../../components/core/Utility/index";
import { Button } from "@vds/buttons";
import { Header, SLButton } from "../../components/core/Controls";

import { CustomDropdown, CustomInput, CalendarWrapper } from "./CreateNewJob";
import { sessionData, jobDurationFormatter, handleStartDate, handleERPError } from "../../utility/sessionData";
import { InputNumber } from "primereact/inputnumber";
import { css } from "@emotion/react";
import styled from "styled-components";
import moment from "moment";
import { getInstallersList } from "../../services/scheduler/getInstallersList";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { Loader } from "@vds/loaders";
import { SLNotification } from "../../components/core/utility";
import { generate } from "shortid";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import { createSchedule } from "../../services/scheduler/createSchedule";
import { updateJobAutoToManual } from "../../services/scheduler/updateJobAutoToManual";
import ManualScheduleConfirmModal from "./ManualScheduleConfirmModal";
import { rescheduleJob } from "../../services/scheduler/rescheduleJob";
import { switchTab } from "../C4S Decommission - Replacement/utils";
import searchIcon from "../../assets/icons/search_blk.png";
import { PHONE_REGEX } from "../../constants/C4SValidationConstants";
import { installerIDSearch } from "../../services/scheduler/installerIDSearch";
import RescheduleReasonModal from "./RescheduleReasonModal";
import { getReasonlist } from "../../services/scheduler/getReasonlist";
import useOutsideClick from "../../customHook/use-outside-click";
import { getMileageList } from "../../services/scheduler/getMileageList";
import { handleSalesOrg } from "../../pages/OpenJobs/CreateAdditionalFees";

export const CustomisedInput = css`
  border: 0.0625rem solid #d8dada;
  width: 30%;
  height: 2.75rem;
  margin-right: 0.5rem;
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active {
    font-size: 12px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin-top: 0px;
    position: relative;
    border: none;
    color: #000000;
    background-color: transparent;
  }
`;
const CustomLabel = css`
  font-size: 0.75rem;
  font-weight: 400;
  font-family: Verizon-NHG-eTX, Helvetica, Ariel, Sans-serif;
`;

export default function AlternateManualSchedule({ jobData, mapUrl }) {
  const isReschedule = jobData?.isReschedule ? true : false;
  const isExpand = jobData?.isExpand ? true : false;
  const [vendorId, setVendorId] = useState(null);
  const [vendorErpId, setVendorErpId] = useState(isReschedule || isExpand ? jobData.installerId : null);
  const [installer, setInstaller] = useState(isReschedule || isExpand ? jobData.manualVendorId : null);
  const [installerName, setInstallerName] = useState(isReschedule || isExpand ? jobData.manualVendorName : null);
  const [installerErpId, setInstallerErpId] = useState(null);
  const [installerID, setInstallerID] = useState({ value: "", error: false, errorText: "Enter valid Installer ID", success: false });
  const [distance, setDistance] = useState("");
  // const [scheduleTime, setScheduleTime] = useState("1:00 AM");
  const [scheduleHour, setScheduleHour] = useState(1);
  const [scheduleMinutes, setScheduleMinutes] = useState("00");
  const [meridian, setMeridian] = useState("AM");
  const [isLoading, setLoader] = useState(false);
  const [notification, setNotification] = useState();
  const [installersList, setInstallersList] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [slotTime, setSlotTime] = useState("");

  const today = new Date(); // get today's date
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  let nextday = tomorrow.toDateString();

  const [startDate, setStartDate] = useState(isReschedule ? moment(nextday).format("MM/DD/YYYY") : jobData.startDate);
  const [endDate, setEndDate] = useState({
    value: isReschedule ? moment(nextday).format("MM/DD/YYYY") : jobData.startDate,
    error: false,
    errorText: "Select end date",
    success: true,
  });
  const [endTime, setEndTime] = useState("");
  const [scheduleEndTimeHour, setScheduleEndTimeHour] = useState(1);
  const [scheduleEndTimeMinutes, setScheduleEndTimeMinutes] = useState("00");
  const [scheduleEndTimeMeridian, setScheduleEndTimeMeridian] = useState("AM");
  const [rescheduleReasonModalStatus, setRescheduleReasonModalStatus] = useState({
    isModalOpen: false,
    slotTime: "",
    reasonList: [],
    isManualSchedule: false,
  });

  const handleClickOutside = () => {
    setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);

  let address1 = jobData.address1 != undefined ? jobData.address1 : jobData.installationAddress?.addressOne;
  let addres2 = jobData.address2 != undefined ? jobData.address2 : jobData.installationAddress?.addressTwo;
  let city = jobData.city != undefined ? jobData.city : jobData.installationAddress?.city;
  let state = jobData.stateCode != undefined ? jobData.stateCode : jobData.installationAddress?.state;
  let zip = jobData.zipCode != undefined ? jobData.zipCode : jobData.installationAddress?.zip;
  let country = jobData.country != undefined ? jobData.country : jobData.installationAddress?.country;
  let jobId = jobData.id ? jobData.id : jobData.jobId;
  let workTicket =
    isExpand || isReschedule ? jobData?.workTicket : jobData?.jobDetails[0]?.workTickets.map((workTickets) => workTickets.workTicket).join(",");
  const {
    accountId,
    source,
    accountDetails,
    cspDetails,
    caseDetails,
    historicalAddressDetails,
    // chargeableStates
  } = useContext(AccountDetailsContext);

  const salesOrgBasedCurrency = {
    SO_3001: "AUD",
    SO_6901: "NZD",
    SO_1001: "EUR",
    SO_1201: "EUR",
    SO_4203: "EUR",
    SO_4211: "EUR",
    SO_3701: "EUR",
    SO_1101: "GBP",
    SO_2901: "CAD",
    SO_9001: "USD",
    SO_5501: "MXN",
  };
  const [customerType, setCustomerType] = useState("");
  let initialStartDate = isReschedule ? moment(nextday).format("MM/DD/YYYY") : jobData.startDate;
  const [mileageType, setMileageType] = useState("");
  const [selectedMileageType, setSelectedMileageType] = useState("");
  const [isGetMileageError, setIsGetMileageError] = useState(false);
  const defaultCsp = cspDetails?.cspDetails?.filter((cspDetail) => cspDetail?.DefaultCSP === "1")[0] ?? null;

  useEffect(() => {
    getMileage();
    if (isReschedule || isExpand) {
      handleFields("Distance", jobData.totalMiles);
      handleFields("Installer ID", jobData.manualInstallerId);
    }
    if (accountDetails.vztSegment.toLocaleLowerCase().includes("smb")) {
      setCustomerType("B");
    } else if (accountDetails.vztSegment.toLocaleLowerCase().includes("micro")) {
      setCustomerType("M");
    } else if (
      accountDetails.vztSegment.toLocaleLowerCase().includes("government") ||
      accountDetails.vztSegment.toLocaleLowerCase().includes("public sector")
    ) {
      setCustomerType("G");
    } else if (accountDetails.vztSegment.toLocaleLowerCase().includes("enterprise")) {
      setCustomerType("E");
    } else if (accountDetails.vztSegment.toLocaleLowerCase().includes("strategic")) {
      setCustomerType("S");
    }
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   (isReschedule || isExpand) && handleFields("Installer", jobData.manualVendorName);
  // }, [installersList]);

  const notify = (content, type, isNotificationWithTimer = true) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />);
    if (isNotificationWithTimer) {
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  const getInstallers = async () => {
    updateNewrelicStats();
    setLoader(true);
    let response = await getInstallersList();
    if (response.status === 200 && response?.data?.data.length) {
      setInstallersList(response.data.data);
      setLoader(false);
    } else {
      setInstallersList([]);
      setLoader(false);
      notify("Unable to fetch Installers List", "error", false);
    }
  };

  const handleFields = (field, value) => {
    switch (field) {
      case "Installer":
        setInstaller(value);
        break;
      case "Distance":
        if (value && Number(value) >= 0) {
          setDistance(value);
        } else {
          setDistance("");
        }
        break;
      case "Installer ID": {
        if (!jobData?.manualVendorName) {
          if (value && value.length) {
            if (PHONE_REGEX?.test(value)) {
              setInstallerID({ value, error: false, errorText: "Enter valid Installer ID", success: true });
            } else {
              setInstallerID({ value, error: true, errorText: "Enter valid Installer ID", success: false });
            }
          } else {
            setInstallerID({ value: "", error: true, errorText: "Enter Installer ID", success: false });
          }
          setInstallerName("");
          setInstaller("");
          setInstallerErpId("");
          setVendorId("");
          setVendorErpId("");
        }
        break;
      }
      case "Job Start Date": {
        let date = moment(value, "MMMM DD,YYYY").format("MM/DD/YYYY");
        setStartDate(date);
        if (endDate.value && new Date(endDate.value) < new Date(value)) {
          setEndDate((prevState) => ({
            ...prevState,
            error: true,
            errorText: "End date should be start date and later",
            success: false,
          }));
        } else {
          setEndDate((prevState) => ({
            ...prevState,
            error: false,
            errorText: "",
            success: true,
          }));
        }
        break;
      }
      case "Hour":
        setScheduleHour(value);
        // setScheduleTime(value + ":" + scheduleMinutes + " " + (value > 11 ? "PM" : "AM"));
        break;
      case "Minutes":
        setScheduleMinutes(value);
        // setScheduleTime(scheduleHour + ":" + value + " " + (scheduleHour > 11 ? "PM" : "AM"));
        break;
      case "Meridian":
        setMeridian(value);
        break;
      case "Job End Date": {
        if (value && value.length) {
          let date = moment(value, "MMMM DD,YYYY").format("MM/DD/YYYY");
          setEndDate((prevState) => ({ ...prevState, value: date, error: false, success: true }));
        } else {
          setEndDate((prevState) => ({ ...prevState, value: "", error: true, errorText: "Please Select End Date", success: false }));
        }
        break;
      }
      case "Schedule End Time Hour":
        setScheduleEndTimeHour(value);
        break;
      case "Schedule End Time Minutes":
        setScheduleEndTimeMinutes(value);
        break;
      case "Schedule End Time Meridian":
        setScheduleEndTimeMeridian(value);
        break;
      default:
        break;
    }
  };

  const installationAddress = useMemo(() => {
    let address2 = addres2 ? addres2 + ", " : "";
    return address1 + ", " + address2 + city + ", " + state + ", " + zip + ", " + country;
  }, []);

  const appointmentLength = useMemo(() => {
    let jobLength = jobData?.appointmentLength ? jobData.appointmentLength : jobData.jobDuration;
    return jobLength;
  }, []);

  const vehicleCount = useMemo(() => {
    let count = jobData?.jobDetails?.length && jobData?.jobDetails[0]?.vehicleCount ? jobData.jobDetails[0].vehicleCount : jobData.vehicles;
    return count;
  }, []);

  const updateJobHandler = async () => {
    setLoader(true);
    let response = await updateJobAutoToManual(jobData.id);
    if (response.status === 200 && !!response?.data?.data?.id) {
      setLoader(false);
      return true;
    }
  };

  let emptyMaterialLineId = [],
    emptyMaterialId = [],
    emptyItemDescription = [],
    emptyQuantity = [],
    emptyProcessingCode = [],
    emptyLineItemUniqueId = [],
    emptyUnitMeasure = [];

  const createJobSchedule = async (notesToTech, notesToVendor) => {
    let scheduleJob = true;
    let lineItemForMileage;
    if (jobData.fromAutoSchedule) {
      scheduleJob = await updateJobHandler();
    }
    if (scheduleJob) {
      const { firstName, lastName, hour } = getPayloadData();
      let salesOrg = jobData?.salesOrg ? jobData?.salesOrg?.split(" ")[0] : "";
      let cspCaseDetails = {
        assignedTo: null,
        assignedToEmail: null,
        assignedToEid: null,
      };
      if (source.source?.toLowerCase() === "case") {
        let caseData = caseDetails.caseDetails.length ? caseDetails.caseDetails.find((item) => item.DefaultCase === "1") : {};
        cspCaseDetails = {
          assignedTo: caseData?.CaseOwner || null,
          assignedToEmail: caseData?.CaseOwnerEmail || null,
          assignedToEid: caseData?.CaseOwnerEId || null,
        };
      } else {
        let cspData = cspDetails.cspDetails.length ? cspDetails.cspDetails.find((item) => item.DefaultCSP === "1") : {};
        cspCaseDetails = {
          assignedTo: cspData?.CSPOwner || null,
          assignedToEmail: cspData?.CSPOwnerEmail || null,
          assignedToEid: cspData?.CSPOwnerEId || null,
        };
      }

      let data = {
        Header: {
          requestControlId: "vzc_" + Date.now(),
        },
        Body: {
          header: {
            clientAppName: "VZC",
            clientAppUserName: "ECOM",
          },
          ignoreCache: false,
          vendorId: installer,
          startDate: startDate,
          orderNum: jobId,
          serviceJobType: jobData.jobDetails?.length ? jobData.jobDetails[0]?.jobType : jobData?.jobType ? jobData.jobType : null,
          apptInfo: {
            accountName: accountDetails.customerName,
            homePhone: jobData && jobData.phone && jobData?.phone?.replace(/\D/g, ""),
            workPhone: "",
            mobilePhone: "",
            primaryPhoneType: "Home",
            emailAddress: jobData.email,
            firstName: firstName,
            lastName: lastName,
            SpecialInstructions: notesToTech,
            vendorMessages: notesToVendor,
            installApptSelectedDate: {
              availableDate: moment(startDate, "MM/DD/YYYY").format("YYYYMMDD"),
              slotStartTime: hour + scheduleMinutes,
              totalMiles: Number(distance),
              scheduleEndDate: moment(endDate.value, "MM/DD/YYYY").format("YYYYMMDD"),
              scheduleEndTime: getScheduleEndTimeHour() + scheduleEndTimeMinutes,
              // vendorId: vendorId,
              // erpVendorId: vendorErpId,
            },
          },
          serviceAddress: {
            lastName: lastName,
            firstName: firstName,
            address1: address1,
            address2: addres2,
            city: city,
            state: state,
            postalCode: zip,
            country: country.toUpperCase(),
          },
          vehicleInformation: [],
          vehicleCount: jobData?.jobDetails?.length && jobData?.jobDetails[0]?.vehicleCount ? jobData.jobDetails[0].vehicleCount : jobData.vehicles,
          requestControlID: "",
          languagePref: "E",
          mdnPromo: "Y",
          serviceCost: "60.0",
          eventCorrelationId: "",
          launchType: "FCL",
          addressType: "SFU",
          CustomerType: customerType,
          workTicketNumber: workTicket,
          documentType: source.source === "csp" ? "ZINS" : "ZSRQ",
          salesOrg: salesOrg,
          currencyCode: accountDetails?.currencyCode ?? "",
          distributionChannel: jobData?.distributionChannel ? jobData.distributionChannel : "",
          division: jobData?.division ? jobData.division : "",
          crmReferenceId: source.source === "csp" ? cspDetails.cspDetails[0].CRM_Ref_ID : caseDetails?.caseDetails[0].CaseNumber,
          contractAccount: jobData.contractAccount,
          erpVendorId: vendorErpId,
          salesRepId: accountDetails.employeeId,
          assignedTo: accountDetails.employeeId,
          vendorMessage: notesToVendor, // from job details
          soldToParty: accountDetails.erpBpNumber, //from csp (1ERP BP number)
          shipToParty: accountDetails.erpBpNumber, //from csp (1ERP BP number)
          subStatus: "",
          oldDeliveryNo: "",
          partners: [
            {
              party: accountDetails?.resellerBusinessPartnerNumber || accountDetails?.erpBpNumber || "",
              partRoleCode: "AG",
            },
            {
              party: accountDetails.erpBpNumber ?? "",
              partRoleCode: "WE",
            },
            {
              party: accountDetails?.resellerBusinessPartnerNumber || accountDetails?.erpBpNumber || "",
              partRoleCode: "Z6",
              accountName: accountDetails.customerName,
              contactName: jobData.contactName,
              contactPhone: jobData.phone,
              contactEmail: jobData.email,
              street: jobData.address1,
              city: jobData.city,
              state: jobData.stateCode,
              country: jobData.country.toUpperCase(),
              zip: jobData.zipCode,
              telephone: "",
            },
          ],
          itemDetails: [
            ...jobData.jobDurationDetails.map((job) => {
              let maxLineID = "000000";
              if (job.materialLineID > maxLineID) {
                maxLineID = job.materialLineID;
              }
              lineItemForMileage = ("0000" + (+maxLineID + 10)).slice(-6);
              return {
                materialLineId: job.materialLineID, //id
                materialId: job.materialId,
                itemDescription: job.materialName,
                quantity: job.quantity === null && job.processingCode === "ZBTP" ? 0 : job.quantity,
                unitMeasure: job.unitOfMeasure,
                techRefId: job.techRefId,
                processingCode: job.processingCode,
                lineItemUniqueId: job.lineItemUniqueId,
                customerPONumber: source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null, // FROM CSP ONLY
                customerPONumberKut: source.source === "case" ? cspDetails?.cspDetails[0]?.Provider_Order : null, // FROM CASE ONLY
                baseItemId: "", //empty
                esnToBeReturned: "", //empty
                contractId: job?.wtMaterialContractId ? job?.wtMaterialContractId : null,
                sapId: job?.contractCode ? job?.contractCode : null,
                contractName: job?.contractName?.length > 0 ? job?.contractName : null,
                wtMaterialId: job?.wtMaterialId || "",
              };
            }),
            {
              materialLineId: lineItemForMileage, //id
              materialId: selectedMileageType?.materialId ?? "",
              itemDescription: selectedMileageType?.materialName ?? "",
              quantity: Number(distance),
              unitMeasure: "",
              techRefId: "",
              processingCode: "ZTML",
              lineItemUniqueId: jobId + "_" + lineItemForMileage, // need to get the job id
              customerPONumber: source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null, // FROM CSP ONLY
              customerPONumberKut: source.source === "case" ? cspDetails?.cspDetails[0]?.Provider_Order : null, // FROM CASE ONLY
              baseItemId: "", //empty
              esnToBeReturned: "", //empty
              contractId: null,
              sapId: null,
              contractName: null,
              wtMaterialId: selectedMileageType?.wtMaterialId ?? "",
            },
          ],
        },
        modifiedBy: accountDetails?.employeeFullName,
        modifiedByEmail: accountDetails?.employeeEmail,
        modifiedByEid: accountDetails?.employeeId,
        assignedTo: cspCaseDetails?.assignedTo,
        assignedToEmail: cspCaseDetails?.assignedToEmail,
        assignedToEid: cspCaseDetails?.assignedToEid,
      };
      let errorMsg = [];
      let missingFieldsArray = [];
      for (const key of Object.keys(data.Body)) {
        if (
          key === "salesOrg" ||
          key === "currencyCode" ||
          key === "distributionChannel" ||
          key === "division" ||
          key === "crmReferenceId" ||
          key === "erpVendorId" ||
          key === "contractAccount" ||
          key === "assignedTo"
        ) {
          if (data.Body[key] === "" || data.Body[key] === undefined || data.Body[key] === null) {
            missingFieldsArray.push(key);
          }
        }
      }
      for (const key of Object.keys(data.Body.serviceAddress)) {
        if (key === "address1" || key === "city" || key === "state" || key === "postalCode" || key === "country") {
          if (data.Body.serviceAddress[key] === "" || data.Body.serviceAddress[key] === undefined || data.Body.serviceAddress[key] === null) {
            missingFieldsArray.push(key);
          }
        }
      }
      if (missingFieldsArray.length > 0) {
        return setTimeout(() => {
          notify(
            "Unable to schedule job due to missing information. Kindly contact IT team for support. Missing information: " +
              missingFieldsArray.toString() +
              ".",
            "error",
            false
          );
        }, 10);
      } else if (accountDetails.resellerBusinessPartnerNumber === null && accountDetails.erpBpNumber === null) {
        return setTimeout(() => {
          notify(
            "Unable to schedule job due to missing mandatory information (Mandatory: Reseller Business Partner Number). Kindly contact IT team for support.",
            "error",
            false
          );
        }, 10);
      } else {
        data.Body?.itemDetails?.filter((data) => {
          for (const key of Object.keys(data)) {
            if (
              key === "materialLineId" ||
              key === "materialId" ||
              key === "itemDescription" ||
              key === "quantity" ||
              key === "processingCode" ||
              key === "lineItemUniqueId"
            ) {
              if (data[key] === "" || data[key] === null || data[key] === undefined) {
                captureMaterialId(key, data.materialId);
              }
            }
          }
        });
        if (emptyMaterialId.length > 0) errorMsg.push(` ${"Material Id"} is missing for ${emptyMaterialId.toString()}`);
        if (emptyMaterialLineId.length > 0) errorMsg.push(` ${"Material Line Id"} is missing for ${emptyMaterialLineId.toString()}`);
        if (emptyItemDescription.length > 0) errorMsg.push(` ${"Item Description"} is missing for ${emptyItemDescription.toString()}`);
        if (emptyQuantity.length > 0) errorMsg.push(` ${"Quantity"} is missing for ${emptyQuantity.toString()}`);
        if (emptyProcessingCode.length > 0) errorMsg.push(` ${"Processing Code"} is missing for ${emptyProcessingCode.toString()}`);
        if (emptyLineItemUniqueId.length > 0) errorMsg.push(` ${"LineItem Unique Id"} is missing for ${emptyLineItemUniqueId.toString()}`);
        if (emptyUnitMeasure.length > 0) errorMsg.push(` ${"Unit of Measure"} is missing for ${emptyUnitMeasure.toString()}`);

        if (errorMsg.length > 0) {
          return setTimeout(() => {
            notify(
              <>
                {"Unable to update the job status due to below missing information. Kindly contact IT team for support. Missing Information: "}
                {errorMsg.map((msg) => (
                  <div key={generate()}>{msg}</div>
                ))}
              </>,
              "error",
              false
            );
          }, 10);
        }
      }
      setLoader(true);
      let response = await createSchedule(jobId, data);
      if (response?.status === 200 && response?.data?.data?.respMsg?.toLowerCase() === "success") {
        notify("Job scheduled successfully", "success");
        setTimeout(() => {
          setNotification(null);
          setLoader(false);
          switchTab(2, { data: { jobId: jobData.id } });
        }, 5000);
      } else if (response && response?.data?.statusCode === "200" && response?.data?.data?.error && Object.keys(response?.data?.data?.error)) {
        setLoader(false);
        let errorDetail = response?.data?.data?.error?.statusDetail;
        errorDetail?.length && notify(errorDetail[0]?.errorText, "error", false);
      } else if (response && response?.data?.statusCode === "404") {
        setLoader(false);
        let message = response?.data?.message;
        response?.data?.data ? handleERPError(response?.data, notify) : notify(message, "error", false);
      } else {
        setLoader(false);
        notify("Unable to schedule job due to an unexpected error.", "error", false);
      }
    } else {
      setLoader(false);
      notify("Unable to update job due to an unexpected error.", "error", false);
    }
  };

  const captureMaterialId = (materialName, materialId) => {
    switch (materialName) {
      case "materialLineId": {
        emptyMaterialLineId.push(materialId);
        break;
      }
      case "materialId": {
        emptyMaterialId.push(materialId);
        break;
      }
      case "itemDescription": {
        emptyItemDescription.push(materialId);
        break;
      }
      case "quantity": {
        emptyQuantity.push(materialId);
        break;
      }
      case "processingCode": {
        emptyProcessingCode.push(materialId);
        break;
      }
      case "lineItemUniqueId": {
        emptyLineItemUniqueId.push(materialId);
        break;
      }
      case "unitMeasure": {
        emptyUnitMeasure.push(materialId);
        break;
      }
    }
  };

  const confirmationModalHandler = (toShow) => {
    if (toShow) {
      let hour = getSlotTimeHour() + scheduleMinutes;
      setSlotTime(hour);
      setShowConfirmationModal(true);
    } else {
      setSlotTime("");
      setShowConfirmationModal(false);
    }
  };

  const getSlotTimeHour = () => {
    let hour = scheduleHour;
    if (meridian === "PM") {
      hour = hour < 12 ? (hour += 12) : hour;
    } else {
      hour = hour === 12 ? (hour -= 12) : hour;
      hour = hour.toString().length < 2 ? "0" + hour : hour;
    }
    return hour;
  };

  const getScheduleEndTimeHour = () => {
    let hour = scheduleEndTimeHour;
    if (scheduleEndTimeMeridian === "PM") {
      hour = hour < 12 ? (hour += 12) : hour;
    } else {
      hour = hour === 12 ? (hour -= 12) : hour;
      hour = hour.toString().length < 2 ? "0" + hour : hour;
    }
    return hour;
  };

  const getPayloadData = () => {
    let name,
      firstName = "",
      lastName = "";
    if (jobData.contactName?.length > 0) {
      name = jobData.contactName.split(" ");
      firstName = name.length > 1 ? name.slice(0, name.length - 1).join(" ") : name[0];
      lastName = name.length > 1 ? name[name.length - 1] : "";
    }
    let hour = getSlotTimeHour();
    return { firstName, lastName, hour };
  };

  const handleRescheduleReasonListModal = async () => {
    updateNewrelicStats();
    setLoader(true);

    let response = await getReasonlist("VZC_Reschedule");
    if (response.status === 200 && response?.data?.statusCode === "200") {
      setLoader(false);
      setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: true, reasonList: response.data.data, isManualSchedule: true });
    } else {
      setLoader(false);
      notify("Unable to retrieve reschedule reasons.", "error", false);
    }
  };

  const handleRescheduleManual = async (reson) => {
    let isRescheduleJob = true;
    if (jobData.fromAutoSchedule) {
      isRescheduleJob = await updateJobHandler();
    }
    if (isRescheduleJob) {
      const { firstName, lastName, hour } = getPayloadData();
      let cspCaseDetails = {
        assignedTo: null,
        assignedToEmail: null,
        assignedToEid: null,
      };
      if (source.source?.toLowerCase() === "case") {
        let caseData = caseDetails.caseDetails.length ? caseDetails.caseDetails.find((item) => item.DefaultCase === "1") : {};
        cspCaseDetails = {
          assignedTo: caseData?.CaseOwner || null,
          assignedToEmail: caseData?.CaseOwnerEmail || null,
          assignedToEid: caseData?.CaseOwnerEId || null,
        };
      } else {
        let cspData = cspDetails.cspDetails.length ? cspDetails.cspDetails.find((item) => item.DefaultCSP === "1") : {};
        cspCaseDetails = {
          assignedTo: cspData?.CSPOwner || null,
          assignedToEmail: cspData?.CSPOwnerEmail || null,
          assignedToEid: cspData?.CSPOwnerEId || null,
        };
      }
      let data = {
        Header: {
          transactionId: "VZC_" + Date.now(),
          client_name: "VZC",
          isReschedule: true,
        },
        Body: {
          header: {
            clientAppName: "VZC",
            clientAppUserName: "",
            clientAppUserFirstName: firstName,
            clientAppUserLastName: lastName,
            clientAppUserEmail: jobData.contactEmail,
            clientAppUserRole: "REP",
            isReschedule: true,
          },
          vendorId: installer,
          startDate: startDate,
          zipcode: jobData.installationAddress.zip,
          orderNum: jobData.jobId,
          serviceJobId: jobData.serviceJobId,
          rescheduleReasonCode: reson.code,
          comments: "",
          rescheduleReason: reson.description,
          serviceJobType: jobData.jobType,
          updateBy: "",
          signature: "",
          createdBy: accountDetails.sfdcUsername,
          apptInfo: {
            installApptSelectedDate: {
              availableDate: moment(startDate, "MM/DD/YYYY").format("YYYYMMDD"),
              slotStartTime: hour + scheduleMinutes,
              totalMiles: Number(distance),
              scheduleEndDate: moment(endDate.value, "MM/DD/YYYY").format("YYYYMMDD"),
              scheduleEndTime: getScheduleEndTimeHour() + scheduleEndTimeMinutes,
            },
          },
        },
        modifiedBy: accountDetails?.employeeFullName,
        modifiedByEmail: accountDetails?.employeeEmail,
        modifiedByEid: accountDetails?.employeeId,
        assignedTo: cspCaseDetails?.assignedTo,
        assignedToEmail: cspCaseDetails?.assignedToEmail,
        assignedToEid: cspCaseDetails?.assignedToEid,
      };
      let missingFieldsArray = [];
      for (const key of Object.keys(data.Body)) {
        if (key === "startDate" || key === "zipcode" || key === "orderNum" || key === "serviceJobId" || key === "serviceJobType") {
          if (data.Body[key] === "" || data.Body[key] === undefined || data.Body[key] === null) {
            missingFieldsArray.push(key);
          }
        }
      }
      if (missingFieldsArray.length > 0) {
        setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: false, isManualSchedule: false });
        return setTimeout(() => {
          notify(
            "Unable to reschedule the job due to missing information. Kindly contact IT team for support. Missing information: " +
              missingFieldsArray.toString() +
              ".",
            "error",
            false
          );
        }, 10);
      }
      setLoader(true);
      let response = await rescheduleJob(jobData.jobId, data);
      if (response?.status === 200 && response?.data?.data?.respMsg.toLowerCase() === "success") {
        notify("Job Rescheduled successfully", "success");
        sessionData(jobId);
        setTimeout(() => {
          setNotification(null);
          setLoader(false);
          switchTab(2, { data: { jobId: jobData.id } });
        }, 5000);
        setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: false, isManualSchedule: false });
      } else {
        setLoader(false);
        setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: false, isManualSchedule: false });
        notify("Unable to update job due to an unexpected error", "error", false);
      }
    } else {
      setLoader(false);
      notify("Unable to update job due to an unexpected error", "error", false);
    }
  };

  const handleInstallerIDSearch = async () => {
    if (installerID.success) {
      setLoader(true);
      let response = await installerIDSearch(installerID.value);
      if (response?.status === 200) {
        response = response?.data?.data;
        if (customerType === "G" && accountDetails.billingCountry === "USA" && response.isGovernmentAllowed === "NO") {
          notify("This installer is not allowed for this account.", "error", false);
        } else {
          setInstallerName(response.installerName);
          setInstaller(response.installerId);
          setInstallerErpId(response.erpInstallerId);
          setVendorId(response.vendorId);
          setVendorErpId(response.erpVendorId);
        }
      } else {
        notify("We are currently experiencing issues, please try again", "error", false);
        setInstallerName("");
        setInstaller("");
        setInstallerErpId("");
        setVendorId("");
        setVendorErpId("");
      }
      setLoader(false);
    }
  };
  const getMileage = async () => {
    updateNewrelicStats();
    setLoader(true);
    let salesOrg = jobData?.salesOrg ? jobData?.salesOrg?.split(" ")[0] : "";
    let response = await getMileageList(salesOrg);
    if (response.status === 200 && response?.data?.data?.length) {
      let mileageList = response.data.data.map((records) => {
        return {
          mileageType: `${records.materialName} - ${records.materialId}`,
          wtMaterialId: records.wtMaterialId,
          materialId: records.materialId,
          materialName: records.materialName,
          salesOrgId: records.salesOrgId,
        };
      });
      let distanceMMID = source?.source.toLowerCase() == "csp" ? cspDetails?.cspDetails[0].distanceMMID : caseDetails?.caseDetails[0].distanceMMID;
      let defaultMileage = mileageList.filter((records) => records.materialId === distanceMMID)[0];
      setSelectedMileageType(defaultMileage);
      setMileageType(defaultMileage?.mileageType);
      setLoader(false);
    } else {
      setLoader(false);
      setIsGetMileageError(true);
      notify("Unable to fetch mileage list due to an unexpected error", "error");
    }
  };

  const rescheduleDate = `${moment(startDate).format("dddd MMMM Do YYYY")} at ${scheduleHour}:${scheduleMinutes} ${meridian}`;

  return (
    // <>
    //   <Grid
    //     bleed="1272"
    //     rowGutter="10px"
    //     colSizes={{
    //       mobile: 1,
    //       tablet: 2,
    //       desktop: 3,
    //     }}
    //   >
    //     <Row css={{ marginTop: "2rem" }}>
    //       <Col css={{ minWidth: "50%", paddingLeft: "0" }}>
    //         <Header>Schedule Job</Header>
    //         <FlexRow css={{ marginTop: "20px" }}>
    //           <SmallText bold>Job Type:&nbsp;</SmallText>
    //           <SmallText>Support/RMA</SmallText>
    //         </FlexRow>
    //         <FlexRow>
    //           <SmallText bold>Requested Date:&nbsp;</SmallText>
    //           <SmallText>06/10/2023</SmallText>
    //         </FlexRow>
    //         <FlexRow>
    //           <SmallText bold>Duration:&nbsp;</SmallText>
    //           <SmallText>1.5 Hours</SmallText>
    //           <SmallText bold>&nbsp;Vehicle Count:&nbsp;</SmallText>
    //           <SmallText>3</SmallText>
    //         </FlexRow>
    //         <FlexRow>
    //           <SmallText bold>Work Ticket Number:&nbsp;</SmallText>
    //           <SmallText>390030</SmallText>
    //         </FlexRow>
    //         <FlexRow>
    //           <SmallText bold>Job ID:&nbsp;</SmallText>
    //           <SmallText>SP_005000761SJ</SmallText>
    //         </FlexRow>
    //         <FlexRow css={{ marginBottom: "2rem" }}>
    //           <SmallText bold>Address:&nbsp;</SmallText>
    //           <SmallText>187 TOWNE PARK DR, LAWRENCEVILLE, GA, 30044</SmallText>
    //         </FlexRow>
    //         <CustomDropdown
    //           width="60%"
    //           type="text"
    //           label="Installer"
    //           marginTop="1rem"
    //           marginBottom="0.3rem"
    //           // disabled={isFieldsDisabled}
    //           // disableValue={!csp.value.name}
    //           // error={csp.error}
    //           // errorText={csp.errorText}
    //           value={installer ? installer : "Please select"}
    //           onChange={(event) => handleFields("Installer", event.target.value)}
    //         >
    //           <option disabled style={{ backgroundColor: "lightgrey" }}>
    //             Please select
    //           </option>
    //           <option key="Orbital Kentucky" value="Orbital Kentucky">
    //             Orbital Kentucky
    //           </option>
    //         </CustomDropdown>
    //         <CustomInput
    //           // width="40%"
    //           boxWidth="15%"
    //           type="text"
    //           label="Distance"
    //           // disabled={isFieldsDisabled}
    //           // error={name.error}
    //           // errorText={name.errorText}
    //           // success={name.success}
    //           value={distance}
    //           // maxLength={50}
    //           onChange={(event) => handleFields("Distance", event.target.value)}
    //         />
    //         <CalendarWrapper>
    //           <CustomInput
    //             width="88%"
    //             type="calendar"
    //             label="Select Job Start Date"
    //             // disabled={isFieldsDisabled}
    //             // error={startDate.error}
    //             // errorText={startDate.errorText}
    //             // success={startDate.success}
    //             value={startDate}
    //             // selectedDate={new Date(startDate.value)}
    //             minDate={new Date(handleStartDate())}
    //             onChange={(event) => handleFields("Job Start Date", event)}
    //           />
    //         </CalendarWrapper>
    //         {/* <CustomDropdown
    //         width="60%"
    //         type="text"
    //         label="Scheduled Time"
    //         marginBottom="0.3rem"
    //         // disabled={isFieldsDisabled}
    //         // disableValue={!csp.value.name}
    //         // error={csp.error}
    //         // errorText={csp.errorText}
    //         value={scheduleTime ? scheduleTime : "Please select"}
    //         onChange={(event) => setScheduleTime(event.target.value)}
    //       >
    //         <option disabled style={{ backgroundColor: "lightgrey" }}>
    //           Please select
    //         </option>
    //         <option key="2.30 PM" value="2.30 PM">
    //           2.30 PM
    //         </option>
    //       </CustomDropdown> */}
    //         <label css={CustomLabel}>Scheduled Time</label>
    //         <FlexRow css={{ marginBottom: "0.3rem", marginTop: "0.3rem" }}>
    //           <InputNumber
    //             value={scheduleHour}
    //             min={1}
    //             max={23}
    //             showButtons
    //             onChange={(e) => handleFields("Hour", e.value)}
    //             css={CustomisedInput}
    //           ></InputNumber>
    //           <FlexRow alignContent="center" css={{ marginRight: "0.5rem" }}>
    //             :
    //           </FlexRow>
    //           <CustomDropdown
    //             width="25%"
    //             type="text"
    //             // label="Scheduled Time"
    //             // marginBottom="0.3rem"
    //             // disabled={isFieldsDisabled}
    //             // disableValue={!csp.value.name}
    //             // error={csp.error}
    //             // errorText={csp.errorText}
    //             value={scheduleMinutes}
    //             onChange={(event) => handleFields("Minutes", event.target.value)}
    //           >
    //             {/* <option disabled style={{ backgroundColor: "lightgrey" }}>
    //             Please select
    //           </option> */}
    //             <option key="00" value="00">
    //               00
    //             </option>
    //             <option key="15" value="15">
    //               15
    //             </option>
    //             <option key="30" value="30">
    //               30
    //             </option>
    //             <option key="45" value="45">
    //               45
    //             </option>
    //           </CustomDropdown>
    //           {/* <FlexRow alignContent="center" css={{ marginLeft: "0.5rem" }}>
    //           {scheduleTime}
    //         </FlexRow> */}
    //         </FlexRow>
    //       </Col>
    //       <Col css={{ minWidth: "50%", paddingRight: "0" }}>
    //         <img src={mapImg} alt="map" css={{ width: "100%", height: "100%" }} />
    //       </Col>
    //     </Row>
    //   </Grid>
    //   <FlexRow css={{ marginTop: "1.5rem" }}>
    //     <Button primary disabled={!installer || !distance || !startDate} css={{ marginLeft: "0px" }}>
    //       Schedule
    //     </Button>
    //     <Button
    //       secondary
    //       css={{ marginLeft: "0.5rem" }}
    //       // onClick={() => handleConfirmation("cancel")}
    //     >
    //       Cancel
    //     </Button>
    //   </FlexRow>
    // </>
    <>
      <Header role="region" ariaLabel={isReschedule ? `Reschedule Job` : `Schedule Job`}>
        {isReschedule ? `Reschedule Job` : `Schedule Job`}
      </Header>
      {notification}
      <Loader active={isLoading} fullscreen />
      {showConfirmationModal && (
        <ManualScheduleConfirmModal
          confirmationModalHandler={confirmationModalHandler}
          scheduleJob={createJobSchedule}
          startDate={startDate}
          slotTime={slotTime}
          timezone=""
        />
      )}
      <SLGridLayout
        bleed="1272"
        rowGutter="10px"
        colSizes={{
          mobile: 1,
          tablet: 2,
          desktop: 3,
        }}
      >
        <SLRowLayout css={{ marginTop: "2rem" }}>
          <Col css={{ minWidth: "50%", paddingLeft: "0" }}>
            <FlexRow>
              <SmallText bold>Job Type:&nbsp;</SmallText>
              <SmallText>{jobData.jobDetails?.length ? jobData.jobDetails[0]?.jobType : jobData?.jobType ? jobData.jobType : "--"}</SmallText>
            </FlexRow>
            <FlexRow>
              <SmallText bold>Requested Date:&nbsp;</SmallText>
              <SmallText>{moment(jobData.startDate).format("MMM D, yyyy")}</SmallText>
            </FlexRow>
            <FlexRow>
              <SmallText bold>Duration:&nbsp;</SmallText>
              <SmallText>{appointmentLength > 1 ? appointmentLength + " Hours" : appointmentLength + " Hour"}</SmallText>
              <SmallText bold>&nbsp;Vehicle Count:&nbsp;</SmallText>
              <SmallText>{vehicleCount}</SmallText>
            </FlexRow>
            <FlexRow>
              <SmallText bold>Work Ticket Number:&nbsp;</SmallText>
              <SmallText>{workTicket}</SmallText>
            </FlexRow>
            <FlexRow>
              <SmallText bold>Job ID:&nbsp;</SmallText>
              <SmallText>{jobId}</SmallText>
            </FlexRow>
            <FlexRow>
              <SmallText bold>Address:&nbsp;</SmallText>
              <SmallText>{installationAddress}</SmallText>
            </FlexRow>
            {/* <img src={mapImg} alt="map" css={{ width: "100%", height: "100%" }} /> */}
          </Col>
          <Col css={{ minWidth: "50%", paddingLeft: "0", paddingRight: "0", marginTop: "1rem" }}>
            {/* <CustomDropdown
              width="60%"
              type="text"
              label="Installer"
              marginBottom="0.3rem"
              // disabled={isFieldsDisabled}
              // disableValue={!csp.value.name}
              // error={csp.error}
              // errorText={csp.errorText}
              disabled={!installersList.length}
              value={installer ? installer.installerName : "Please select"}
              onChange={(event) => handleFields("Installer", event.target.value)}
            >
              <option disabled style={{ backgroundColor: "lightgrey" }}>
                Please select
              </option>
              {/* <option key="Orbital Kentucky" value="Orbital Kentucky">
                Orbital Kentucky
              </option> */}
            {/* {installersList.map((installer) => (
                <option key={installer.installerId} value={installer.installerName}>
                  {installer.installerName}
                </option>
              ))}
            </CustomDropdown> */}
            {jobData?.manualVendorName == null ? (
              <FlexRow width="60%">
                <CustomInput
                  type="text"
                  label="Installer ID"
                  aria-label="Installer ID"
                  value={installerID.value}
                  error={installerID.error}
                  errorText={installerID.errorText}
                  success={installerID.success}
                  onChange={(e) => handleFields("Installer ID", e.target.value)}
                  paddingRight="5px"
                  maxLength={10}
                  fontWeight="bold"
                  css={{ paddingBottom: "0.6rem", width: "80%", fontWeight: "bold" }}
                />
                <img
                  src={searchIcon}
                  tabIndex={0}
                  aria-label="Search"
                  css={{ height: "25px", width: "25px", display: "flex", alignSelf: "center", marginLeft: "10px", cursor: "pointer" }}
                  onClick={handleInstallerIDSearch}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleInstallerIDSearch();
                    }
                  }}
                ></img>
              </FlexRow>
            ) : (
              <></>
            )}
            <FlexRow>
              <SmallText css={{ marginBottom: "0.6rem" }} bold>
                Installer Name: &nbsp;
              </SmallText>
              <SmallText css={{ marginBottom: "0.6rem" }} fontWeight="normal">
                {installerName || "--"}
              </SmallText>
            </FlexRow>
            <FlexRow>
              <CustomInput
                boxWidth="15%"
                type="text"
                width="100px"
                label="Distance"
                aria-label="Distance"
                disabled={isReschedule}
                aria-disabled={isReschedule}
                // error={name.error}
                // errorText={name.errorText}
                // success={name.success}
                value={distance}
                // maxLength={50}
                fontWeight="bold"
                onChange={(event) => handleFields("Distance", event.target.value)}
              />
              <CustomInput width="260px" type="text" label="Mileage Type" value={mileageType} disabled paddingRight="5px" />
            </FlexRow>
            {/* <CalendarWrapper>
              <CustomInput
                type="calendar"
                label="Select Job Start Date"
                value={startDate}
                minDate={new Date(handleStartDate())}
                onChange={(event) => handleFields("Job Start Date", event)}
              />
            </CalendarWrapper>
            <label css={CustomLabel}>Scheduled Time</label>
            <FlexRow css={{ marginBottom: "0.3rem", marginTop: "0.3rem" }}>
              <InputNumber
                value={scheduleHour}
                min={1}
                max={11}
                showButtons
                onChange={(e) => handleFields("Hour", e.value)}
                css={CustomisedInput}
              ></InputNumber>
              <FlexRow alignContent="center" css={{ marginRight: "0.5rem" }}>
                :
              </FlexRow>
              <CustomDropdown
                width="30%"
                type="text"
                value={scheduleMinutes}
                onChange={(event) => handleFields("Minutes", event.target.value)}
              >
                <option key="00" value="00">
                  00
                </option>
                <option key="30" value="30">
                  30
                </option>
              </CustomDropdown>
              <CustomDropdown
                width="30%"
                marginLeft="0.5rem"
                type="text"
                value={meridian}
                onChange={(event) => handleFields("Meridian", event.target.value)}
              >
                <option key="AM" value="AM">
                  AM
                </option>
                <option key="PM" value="PM">
                  PM
                </option>
              </CustomDropdown>
            </FlexRow>
            <FlexRow css={{ marginTop: "1.5rem" }}>
              <Button primary disabled={!installer || !distance || !startDate} css={{ marginLeft: "0px" }}>
                Schedule
              </Button>
              <Button
                secondary
                css={{ marginLeft: "0.5rem" }}
              >
                Cancel
              </Button>
            </FlexRow> */}
          </Col>
        </SLRowLayout>
        <iframe src={mapUrl} height="605" loading="lazy" css={{ border: "0px", width: "100%" }} allowFullScreen={true} aria-hidden="false"></iframe>
        <SLRowLayout>
          <Col css={{ minWidth: "50%", paddingLeft: "0", marginTop: "1rem" }}>
            <CalendarWrapper>
              <CustomInput
                width="98%"
                type="calendar"
                label="Select Job Start Date"
                aria-label="Select Job Start Date"
                value={startDate ? moment(startDate, "MM/DD/YYYY").format("MMM D, yyyy") : ""}
                selectedDate={new Date(startDate)}
                minDate={new Date(initialStartDate)}
                onChange={(event) => handleFields("Job Start Date", event)}
              />
            </CalendarWrapper>
          </Col>
          <Col css={{ minWidth: "50%", paddingLeft: "0", marginTop: "1rem" }}>
            <CalendarWrapper>
              <CustomInput
                width="98%"
                type="calendar"
                label="Select Job End Date"
                aria-label="Select Job End Date"
                value={endDate.value ? moment(endDate.value, "MM/DD/YYYY").format("MMM D, yyyy") : ""}
                error={endDate.error}
                errorText={endDate.errorText}
                // success={endDate.success}
                selectedDate={new Date(endDate.value)}
                minDate={new Date(startDate)}
                onChange={(event) => handleFields("Job End Date", event)}
              />
            </CalendarWrapper>
          </Col>
        </SLRowLayout>
        <SLRowLayout>
          <Col css={{ minWidth: "50%", paddingLeft: "0", marginTop: "1rem" }}>
            <label css={CustomLabel}>Scheduled Time - Start Time</label>
            <FlexRow css={{ marginBottom: "0.3rem", marginTop: "0.3rem", width: "100%" }}>
              <InputNumber
                value={scheduleHour}
                min={1}
                max={12}
                aria-label="Hour"
                showButtons
                onChange={(e) => handleFields("Hour", e.value)}
                css={CustomisedInput}
              ></InputNumber>
              <FlexRow alignContent="center" css={{ marginRight: "0.5rem" }}>
                :
              </FlexRow>
              <CustomDropdown
                aria-label="Minutes"
                width="30%"
                type="text"
                value={scheduleMinutes}
                onChange={(event) => handleFields("Minutes", event.target.value)}
              >
                <option key="00" value="00">
                  00
                </option>
                <option key="30" value="30">
                  30
                </option>
              </CustomDropdown>
              <CustomDropdown
                width="30%"
                marginLeft="0.5rem"
                aria-label="Meridian"
                type="text"
                value={meridian}
                onChange={(event) => handleFields("Meridian", event.target.value)}
              >
                <option key="AM" value="AM">
                  AM
                </option>
                <option key="PM" value="PM">
                  PM
                </option>
              </CustomDropdown>
            </FlexRow>
          </Col>
          <Col css={{ minWidth: "50%", paddingLeft: "0", marginTop: "1rem" }}>
            <label css={CustomLabel}>Scheduled Time - End Time</label>
            <FlexRow css={{ marginBottom: "0.3rem", marginTop: "0.3rem" }}>
              <InputNumber
                value={scheduleEndTimeHour}
                min={1}
                max={12}
                showButtons
                aria-label="Schedule End Time Hour"
                onChange={(e) => handleFields("Schedule End Time Hour", e.value)}
                css={CustomisedInput}
              ></InputNumber>
              <FlexRow alignContent="center" css={{ marginRight: "0.5rem" }}>
                :
              </FlexRow>
              <CustomDropdown
                width="30%"
                type="text"
                aria-label="Schedule End Time Minutes"
                value={scheduleEndTimeMinutes}
                onChange={(event) => handleFields("Schedule End Time Minutes", event.target.value)}
              >
                <option key="00" value="00">
                  00
                </option>
                <option key="30" value="30">
                  30
                </option>
              </CustomDropdown>
              <CustomDropdown
                width="30%"
                marginLeft="0.5rem"
                type="text"
                aria-label="Schedule End Time Meridian"
                value={scheduleEndTimeMeridian}
                onChange={(event) => handleFields("Schedule End Time Meridian", event.target.value)}
              >
                <option key="AM" value="AM">
                  AM
                </option>
                <option key="PM" value="PM">
                  PM
                </option>
              </CustomDropdown>
            </FlexRow>
          </Col>
        </SLRowLayout>
        <SLRowLayout>
          <Col css={{ minWidth: "55%", paddingLeft: "0", marginTop: "1rem" }}>
            <FlexRow css={{ marginTop: "1.5rem" }}>
              <Button
                primary
                aria-label={isReschedule ? `Reschedule` : `Schedule`}
                role="button"
                disabled={
                  jobData?.manualVendorName == null
                    ? !installerID.success || !distance || !startDate || !endDate.success || !installerName || mileageType.length <= 0
                    : !distance || !startDate || !endDate.success || !installerName || mileageType.length <= 0
                }
                aria-disabled={
                  jobData?.manualVendorName == null
                    ? !installerID.success || !distance || !startDate || !endDate.success || !installerName || mileageType.length <= 0
                    : !distance || !startDate || !endDate.success || !installerName || mileageType.length <= 0
                }
                css={{ marginLeft: "0px" }}
                onClick={() => {
                  isReschedule ? handleRescheduleReasonListModal() : confirmationModalHandler(true);
                }}
              >
                {isReschedule ? `Reschedule` : `Schedule`}
              </Button>
              <Button aria-label="Cancel" role="button" secondary css={{ marginLeft: "0.5rem" }} onClick={() => switchTab(2)}>
                Cancel
              </Button>
            </FlexRow>
          </Col>
        </SLRowLayout>
      </SLGridLayout>

      {rescheduleReasonModalStatus.isModalOpen && (
        <RescheduleReasonModal
          rescheduleReasonModalStatus={rescheduleReasonModalStatus}
          setRescheduleReasonModalStatus={setRescheduleReasonModalStatus}
          jobDetails={jobData}
          handleRescheduleManual={handleRescheduleManual}
          rescheduleDate={rescheduleDate}
          notify={notify}
        />
      )}
    </>
  );
}

export const AlternateManualEntryMetadata = {
  name: "Alternate Manual Entry",
  id: "alternate_manual_schedule",
  component: lazy(() => import("./AlternateManualSchedule")),
  route: "/alternate_manual_schedule",
};
